<template>
  <div class="page-container">
    <h1 class="page-title">Sign In</h1>
    <span class="spacer-50"></span>

    <div v-if="error" class="alert alert-error"> {{error}} </div>

    <form action='#' @submit.prevent='submit'>
      <label for="email">Email</label>
      <input
        id='email'
        type='email'
        name='email'
        value
        required
        autofocus
        v-model='form.email'
      />

      <label for="password">Password</label>
      <input
        id='password'
        type='password'
        name='password'
        required
        v-model='form.password'
      />

      <button type="submit" class="button-primary button-sign-in">Sign In</button>
    </form>

    <p class="account-footer-box">
      Don’t have an account yet? <router-link to="/register">Create Account</router-link>
    </p>

    <p class="account-footer-box">
      Forgot your password? <router-link to="/reset-password">Reset</router-link>
    </p>
  </div>

</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null
    }
  },

  methods: {

    submit () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          this.$router.replace({ name: 'home' })
        })
        .catch(err => {
          this.error = err.message
        })
    }
  }
}

</script>

<style lang="scss">

  .button-sign-in {
    margin-top: 30px;
  }

  .account-footer-box {
    text-align: center;
  }
</style>

<template>
  <div class="page-container">
    <div v-if="error" class="alert alert-full-width alert-error"><i class="material-icons">error_outline</i> <span class="alert-text">{{ error }}</span></div>
    <div v-if="success" class="alert alert-full-width alert-success"><i class="material-icons">done</i> <span class="alert-text">{{ success }}</span></div>

    <h1 class="page-title">Account Settings</h1>

    <form action='#' @submit.prevent='submit'>
      <label for="homepage">Homepage</label>
      <div class="select-wrapper" @change="onHomepageChange($event)">
        <select name="homepage" id="homepage" v-model="accountData.homepage" v-bind:disabled="!isCcmpPro">
          <option value="ccmp">CCMP Training</option>
          <option v-if="isCcmpPro" value="ccmp-pro">CCMP PRO</option>
        </select>
      </div>

      <label for="email">Email</label>
      <input
        id='email'
        type='email'
        name='email'
        value
        required
        disabled
        v-model='accountData.email'
      />

      <label for="first-name">First Name</label>
      <input
        type="name"
        id="first-name"
        name="first-name"
        value
        required
        disabled
        v-model='accountData.firstName'
      >

      <label for="last-name">Last Name</label>
      <input
        type="name"
        id="last-name"
        name="last-name"
        value
        required
        disabled
        v-model='accountData.lastName'
      >

      <label for="last-name">Company</label>
      <input
        type="text"
        id="company"
        name="company"
        value
        disabled
        v-model='accountData.company'
      >
    </form>

    <h3 class="section-title">Change Password</h3>

    <form action="#" @submit.prevent='updatePassword'>
      <label for="old-password">Old Password</label>
      <input
        type="password"
        id="old-password"
        name="old-password"
        required
        value
        v-model='oldPassword'
      >

      <label for="new-password">New Password</label>
      <input
        type="password"
        id="new-password"
        name="new-password"
        required
        value
        v-model='newPassword'
      >

      <button class="button-primary button-with-margin">Change Password</button>
    </form>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      error: null,
      success: null,
      oldPassword: null,
      newPassword: null
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    },

    accountData () {
      return this.$store.state.account
    },

    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    }
  },

  methods: {
    onHomepageChange (event) {
      this.$store.dispatch('updateHomepage', event.target.value)
    },

    showAlert (type, message) {
      this.success = null
      this.error = null

      if (type === 'success') {
        this.success = message
        setTimeout(() => { this.success = null }, 5 * 1000)
      } else {
        this.error = message
      }
    },

    async updatePassword () {
      this.$store.dispatch('setLoadingStatus', true)
      const user = firebase.auth().currentUser
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.oldPassword
      )

      try {
        await user.reauthenticateWithCredential(credential)
        await user.updatePassword(this.newPassword)
        this.showAlert('success', 'Password changed successfully')
        this.$store.dispatch('setLoadingStatus', false)
        this.$store.dispatch('sendPasswordChangeEmail')
      } catch (error) {
        console.error(`Password update failed: ${error}`)
        this.showAlert('error', 'The old password is invalid')
        this.$store.dispatch('setLoadingStatus', false)
      }
    }
  }
}

</script>

<style scoped lang="scss">
  .section-title {
    margin-top: 20px;
    padding: 8px 0;
    border-bottom: 1px solid grey;
  }

  .button-with-margin {
    margin-top: 10px;
  }

  .alert-full-width {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    z-index: 10;
    display: flex;
    line-height: 1.8;
  }

  .alert-text {
    margin-left: 10px;
  }
</style>

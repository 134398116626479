<template>
  <div class="page-container">
    <h1 class="page-title">Welcome</h1>
    <p class="page-description">Thank you for selecting jTask CCMP Webapp. <br>We are excited to provide you access to our training courses and professional business solutions in user-friendly mobile format. <br><br> If you have suggestions for improvement or feedback, please email <a href="mailto:support@jtask.com">support@jtask.com</a></p>
    <span class="spacer-50"></span>
    <button class="button-primary" @click="$router.push('register')">Create Account</button>
    <button class="button-primary" @click="$router.push('login')">Sign In</button>

  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

import { createStore } from 'vuex'
import testDataCcmp from './testDataCcmp.js'
import { db } from './../firebase'
import router from './../router'
import firebase from 'firebase'

const store = createStore({
  state () {
    return {
      user: null,
      account: {
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        homepage: '',
        ccmpPro: false,
        ccmpProActivationType: null // 'code' or 'payment'
      },
      loadingStatus: true,
      ccmpData: {
        lessons: [],
        processSteps: [],
        processSubSteps: [],
        ccmpProTitles: {
          8: 'Quick Start',
          2: 'Analysis',
          3: 'Strategy',
          4: 'Planning',
          5: 'Execution',
          6: 'Completion',
          9: 'Knowledge Base'
        }
      }
    }
  },

  mutations: {
    loadCcmpData (state, newData) {
      state.ccmpData.lessons = newData.processGroups
      state.ccmpData.processSteps = newData.processSteps
      state.ccmpData.processSubSteps = newData.processSubSteps
    },

    loadingStatus (state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },

    setUser (state, data) {
      if (data) {
        state.user = {
          id: data.id,
          displayName: data.displayName,
          email: data.email,
          emailVerified: data.emailVerified,
          idToken: data.idToken
        }
        state.account.email = data.email
        state.account.firstName = data.firstName
        state.account.lastName = data.lastName
        state.account.company = data.company
        state.account.homepage = data.homepage
        state.account.ccmpPro = data.ccmpPro
        state.account.ccmpProActivationType = data.ccmpProActivationType
      } else {
        state.user = null
        state.account.email = ''
        state.account.firstName = ''
        state.account.lastName = ''
        state.account.company = ''
        state.account.homepage = ''
        state.account.ccmpPro = false
        state.account.ccmpProActivationType = null
      }
    },

    setHomepage (state, data) {
      state.account.homepage = data
    }
  },

  actions: {
    setLoadingStatus ({ commit }, status) {
      commit('loadingStatus', status)
    },

    loadCcmpDataFromServer ({ commit }) {
      commit('loadingStatus', true)

      fetch(process.env.VUE_APP_FIREBASE_URL + '/ccmp-data', {
        headers: {
          Authorization: `Bearer ${this.state.user.idToken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          commit('loadCcmpData', data)
          commit('loadingStatus', false)
        })
        .catch((error) => {
          console.error(error.statusText)
        })
    },

    loadCcmpDataFromFile ({ commit }) {
      commit('loadCcmpData', testDataCcmp)
      commit('loadingStatus', false)
    },

    updateHomepage ({ commit }, homepage) {
      db.collection('users').doc(this.state.user.id).update({
        homepage: homepage
      }).then(function () {
        commit('setHomepage', homepage)
      }).catch(function (error) {
        console.error('Error updating homepage: ', error)
      })
    },

    reloadCurrentUser: async function ({ commit }) {
      const currentUser = await firebase.getCurrentUser()
      this.dispatch('setCurrentUser', currentUser)
    },

    setCurrentUser ({ commit }, user) {
      commit('loadingStatus', true)
      if (user) {
        db.collection('users').doc(user.uid).get().then((doc) => {
          if (doc.exists) {
            const userData = doc.data()

            user.getIdToken().then((idToken) => {
              commit('setUser', {
                id: user.uid,
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                idToken: idToken,
                firstName: userData.firstName,
                lastName: userData.lastName,
                company: userData.company,
                homepage: userData.homepage,
                ccmpPro: userData.ccmpPro,
                ccmpProActivationType: userData.ccmpProActivationType
              })

              commit('loadingStatus', false)

              // Load data
              if (user.emailVerified) {
                this.dispatch('loadCcmpDataFromServer')
                // this.dispatch('loadCcmpDataFromFile')
              }
            })
          } else {
            console.error('Could not get user data')
          }
        }).catch(function (error) {
          console.error('Error getting document:', error)
        })
      } else {
        commit('setUser', null)
        commit('loadingStatus', false)
      }
    },

    buyCcmpPro ({ commit }) {
      commit('loadingStatus', true)

      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

      fetch(process.env.VUE_APP_FIREBASE_URL + '/create-checkout-session', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.user.idToken}`
        },
        body: JSON.stringify({ userEmail: this.state.account.email })
      }).then(function (response) {
        return response.json()
      }).then(function (session) {
        return stripe.redirectToCheckout({ sessionId: session.id })
      }).then(function (result) {
        commit('loadingStatus', false)
        if (result.error) {
          console.error(result.error.message)
        }
      }).catch(function (error) {
        console.error('Error:', error)
        commit('loadingStatus', false)
      })
    },

    unlockWithCode ({ commit }, unlockCode) {
      fetch(process.env.VUE_APP_FIREBASE_URL + '/unlock-with-code', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.user.idToken}`
        },
        body: JSON.stringify({ unlockCode: unlockCode, userId: this.state.user.id })
      }).then((response) => {
        this.dispatch('reloadCurrentUser')
        router.push('ccmp-pro-overview')
      }).catch((error) => {
        console.error('Error:', error)
      })
    },

    sendPasswordChangeEmail ({ commit }) {
      fetch(process.env.VUE_APP_FIREBASE_URL + '/send-password-change-email', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.user.idToken}`
        },
        body: null
      }).then((response) => {
      }).catch((error) => {
        console.error('Error:', error)
      })
    }
  }
})

export default store

<template>
  <div class="page-container">
    <h1 class="page-title">Unlock jTask CCMP PRO</h1>
    <span class="spacer-20"></span>

    <h2>Buy jTask CCMP PRO ($69)</h2>
    <button class="button-secondary full-width" @click="buyCcmpPro()"><i class="material-icons">shopping_cart</i> Buy Now</button>

    <span class="spacer-20"></span>

    <h2>Unlock Using Code</h2>
    <p>If you have a code you can enter it here to get full access to CCMP PRO.</p>

    <div class="input-wrapper">
      <input type="text" name="unlock-code" class="input-unlock-code" required placeholder="Enter code ..." v-model="unlockCode">
      <button class="button-tertiary" @click="unlockWithCode()"><i class="material-icons">local_offer</i> Unlock</button>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      unlockCode: ''
    }
  },

  methods: {

    buyCcmpPro () {
      this.$store.dispatch('buyCcmpPro')
    },

    unlockWithCode () {
      this.$store.dispatch('unlockWithCode', this.unlockCode)
    }
  }
}

</script>

<style lang="scss">
  .input-wrapper {
    display: flex;
  }

  .input-unlock-code {
    margin: 0;
    text-transform: uppercase;
  }
</style>

<template>
  <div class="page-container">
    <h1 class="page-title">About jTask</h1>
    <span class="spacer-20"></span>
    <p>jTask specializes in Change and Project Management training.</p>
    <p>jTask is an ACMP®️ Qualified Educational Provider (QEP) and PMI®️ Authorized Training Partner (ATP).</p>
    <p>Since 2015, we've been training our students virtually in how experienced change and project managers use the ACMP®️ Standard for Change Management©️ structured process to achieve their organization's expected benefits.</p>
    <p><b>Headquarters</b><br>
  jTask, Inc.<br>
  1900 S Norfolk St Ste 350<br>
  San Mateo, CA 94403-117<br>
  United States
  <br><br>
  <b>Contact</b><br>
  support@jtask.com <br>
  <a href="tel:877 535 1125">877 535 1125</a>
</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

/*
const testDataStructure = {
  lessons: [
    { id: '1', title: 'Creating a High-Performance Team', quizUrl: 'https://www.surveymonkey.com/r/DQJTRH3' },
    { id: '2', title: 'Starting the Project', quizUrl: 'https://www.surveymonkey.com/r/DQJTRH3' },
    { id: '3', title: 'Doing the Work', quizUrl: 'https://www.surveymonkey.com/r/DQJTRH3' },
    { id: '4', title: 'Keeping the Team on Track', quizUrl: 'https://www.surveymonkey.com/r/DQJTRH3' },
    { id: '5', title: 'Keeping the Business in Mind', quizUrl: 'https://www.surveymonkey.com/r/DQJTRH3' }
  ],

  objectives: [
    {
      id: '1',
      name: 'Determine project team member requirements, appraise team skills, and maintain team knowledge transfer.',
      topic: {
        id: 'A',
        name: 'Build a Team'
      },
      eco: ['1.2', '1.6'],
      lesson: '1',
      enablers: [
        { id: '1', name: 'Appraise teams’ skills', eco: '1.6.1' },
        { id: '2', name: 'Support diversity and inclusion', eco: '1.6.2' },
        { id: '3', name: 'Determine team member requirements', eco: '1.6.3' }
      ],
      deliverables: [
        { id: '1', name: 'Skills list' },
        { id: '2', name: 'Technology' },
        { id: '3', name: 'Resource schedule​' }
      ],
      tools: [
        { id: '1', name: 'Pre-assignment tools' },
        { id: '2', name: 'RACI Matrix' }
      ],
      activities: [
        { id: '1.1', name: 'Building a Team​', time: '15' },
        { id: '1.2', name: 'Define Team Ground  Rules', time: '15' }
      ]
    }
  ]
}
*/

const testData = JSON.parse(`{
  "lessons": [
    {
      "id": "1",
      "title": "Creating a High-Performance Team",
      "quizUrl": "https://www.surveymonkey.com/r/DQJTRH3"
    },
    {
      "id": "2",
      "title": "Starting the Project",
      "quizUrl": "https://www.surveymonkey.com/r/3RNPGBQ"
    },
    {
      "id": "3",
      "title": "Doing the Work",
      "quizUrl": "https://www.surveymonkey.com/r/3W7M6CT"
    },
    {
      "id": "4",
      "title": "Keeping the Team on Track",
      "quizUrl": "https://www.surveymonkey.com/r/3R7KHTP"
    },
    {
      "id": "5",
      "title": "Keeping the Business in Mind",
      "quizUrl": "https://www.surveymonkey.com/r/3SB66TS"
    }
  ],
  "objectives": [
    {
      "airtableId": "recT3uYK1po6kUucj",
      "id": "1.1",
      "name": "Determine project team member requirements, appraise team skills, and maintain team knowledge transfer.",
      "eco": [
        "1.2",
        " 1.6"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "recKoSF5Ta1Dfyvwi",
        "id": "1A",
        "name": "Build a Team"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recVUZJys9wqx3uC2",
      "id": "1.2",
      "name": "Collectively define project ground rules based on context, such as organizational rules and team dynamics.",
      "eco": [
        "1",
        "12"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "rectnuSSemXE8iyvC",
        "id": "1B",
        "name": "Define Team Ground Rules"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recVwMGJuGmi2m2nu",
      "id": "1.3",
      "name": "Determine a negotiation strategy and negotiate project agreements.",
      "eco": [
        "1",
        "8"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "recknHyuDuMR6GE4Z",
        "id": "1C",
        "name": "Negotiate Project Agreements"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recex9Gl1LYkJ9jXK",
      "id": "1.4",
      "name": "Organize around  team strengths and support team task accountability.",
      "eco": [
        "1",
        "4"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "recyS4rjoTYG8q6lD",
        "id": "1D",
        "name": "Empower  Team Members and Stakeholders"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recdgFR6qqRV7jS0Z",
      "id": "1.5",
      "name": "Ensure team members  and stakeholders are adequately trained.",
      "eco": [
        "1",
        "5"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "recCWTTBA9i1qIorb",
        "id": "1E",
        "name": "Train  Team Members and Stakeholders"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec0c2tbdRlEglVW8",
      "id": "1.6",
      "name": "Continually  evaluate the effectiveness of virtual team member engagement.",
      "eco": [
        "1",
        "11"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "recL0nRZyhG5KhANz",
        "id": "1F",
        "name": "Engage  and Support Virtual Teams"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recKqewInpkz4JURZ",
      "id": "1.7",
      "name": "Reach consensus and  support the outcomes of the parties'' agreement.",
      "eco": [
        "1",
        "1"
      ],
      "lesson": "1",
      "topic": {
        "airtableId": "rec3eIosxQieo4PSG",
        "id": "1G",
        "name": "Build a  Shared Understanding about a Project"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec3L4vAVnrR2JiqI",
      "id": "2.1",
      "name": "Assess project needs, complexity and  magnitude to determine the appropriate project methodology/methods and  practices.Assess project needs, complexity and magnitude to determine the  appropriate project methodology/methods and practices.",
      "eco": [
        "2",
        "13"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "recOYAPYvkfi0ReTD",
        "id": "2A",
        "name": "Determine  Appropriate Project Methodology/ Methods and Practices"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recYfHWknJBi4GkNs",
      "id": "2.2",
      "name": "Plan and manage the  scope.",
      "eco": [
        "2",
        "8"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "recSclKGfuDCQPvNw",
        "id": "2B",
        "name": "Plan and Manage Scope"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "reclfehRzI6L7cv3s",
      "id": "2.3",
      "name": "Plan and manage the  budget and resources.",
      "eco": [
        "2",
        "5"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "reclysy9qeQDTXLQC",
        "id": "2C",
        "name": "Plan and Manage Budget and Resources"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recY5WkiPRP2B8ChV",
      "id": "2.4",
      "name": "Plan , prepare,  modify and manage the project schedule based on methodology Task.",
      "eco": [
        "2",
        "6"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "rec1OzSiF4KM7LVDg",
        "id": "2D",
        "name": "Plan and Manage Schedule"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recktk7Q8M5FrP2VV",
      "id": "2.5",
      "name": "Plan and manage the  quality of products and deliverables.",
      "eco": [
        "2",
        "7"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "rec2gYEEBcPLu6Ha4",
        "id": "2E",
        "name": "Plan and Manage Quality of Products/ Deliverables"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec8zhgNnUL3gJ579",
      "id": "2.6",
      "name": "Integrate project  planning activities.",
      "eco": [
        "1.12",
        " 2.9",
        " 3.1"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "recWOLHP7Egix03L8",
        "id": "2F",
        "name": "Integrate Project Planning Activities"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recdY9QplOqkRVRCa",
      "id": "2.7",
      "name": "Plan and manage  procurement strategy.",
      "eco": [
        "2",
        "11"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "recstw8oqSiMpAXsr",
        "id": "2G",
        "name": "Plan and Manage Procurement"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recG3Yjd0DAKj5tgT",
      "id": "2.8",
      "name": "Establish the  project governance structure.",
      "eco": [
        "2",
        "14"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "rec2d6X47ask6qwCv",
        "id": "2H",
        "name": "Establish Project Governance Structure"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recbe1GlOkYOiLiQ5",
      "id": "2.9",
      "name": "Plan and manage  project/phase closure.",
      "eco": [
        "2",
        "17"
      ],
      "lesson": "2",
      "topic": {
        "airtableId": "recC6XS80q9fhrqqv",
        "id": "2I",
        "name": "Plan and Manage Project/Phase Closure"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recanDLBAySVEalXC",
      "id": "3.1",
      "name": "Assess and manage  risks",
      "eco": [
        "2",
        "3"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "recIKwqCY7jO7x6na",
        "id": "3A",
        "name": "Assess  and Manage Risks"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recMofTYdJcJTljIL",
      "id": "3.2",
      "name": "Execute the project  with the urgency required to deliver business value",
      "eco": [
        "2.1",
        " 2.6",
        " 2.9"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "rec8k4TGYscC6syYZ",
        "id": "3B",
        "name": "Execute  Project to Deliver Business Value"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rectfqOV2CtvKe2sp",
      "id": "3.3",
      "name": "Manage  communications",
      "eco": [
        "2",
        "2"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "recPNWLVCRTOvRqsE",
        "id": "3C",
        "name": "Manage  Communications"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recEQ3NqLhPxsFYly",
      "id": "3.4",
      "name": "Engage Stakeholders",
      "eco": [
        "2",
        "4"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "recUwp4UiaUTy6Cjc",
        "id": "3D",
        "name": "Engage Stakeholders"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recTJhsoKHJwVZHGq",
      "id": "3.5",
      "name": "Create project  artifacts",
      "eco": [
        "1.12",
        " 2.9",
        " 3.1"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "rec0GobxAN9DwNyQx",
        "id": "3E",
        "name": "Create  Project Artifacts"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recXgXjCkqtrItxwQ",
      "id": "3.6",
      "name": "Manage project  changes",
      "eco": [
        "2.10"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "rec6gvyxelBCTfQsU",
        "id": "3F",
        "name": "Manage  Project Changes"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "reczXwtJ2SC3toNLS",
      "id": "3.7",
      "name": "Attack issues with  the optimal action to achieve project success",
      "eco": [
        "2",
        "15"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "recNwONSQ4AeYB3KK",
        "id": "3G",
        "name": "Manage  Project Issues"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec3C3tPr1vu5e8fy",
      "id": "3.8",
      "name": "Confirm approach  for knowledge transfers",
      "eco": [
        "1.6",
        " 2.16"
      ],
      "lesson": "3",
      "topic": {
        "airtableId": "recYeX7U1URC8VAl8",
        "id": "3H",
        "name": "Ensure  Knowledge Transfer to Project Continuity"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recrKO2bafpz8xDwj",
      "id": "4.1",
      "name": "Inspire, motivate,  and influence team members and stakeholders.",
      "eco": [
        "1.2",
        " 1.6"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recFr6tEtEK2dY0IF",
        "id": "4A",
        "name": "Lead a  Team"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recBRGPkrHz06YD5n",
      "id": "4.2",
      "name": "Appraise team  performance against key performance indicators.",
      "eco": [
        "1.3",
        " 1.4"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recMDBFnOlaNmAisQ",
        "id": "4B",
        "name": "Support  Team Performance"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recBg03v4yXTfk6xX",
      "id": "4.3",
      "name": "Determine,  prioritize, and remove impediments, obstacles, and blockers for the team.",
      "eco": [
        "1",
        "7"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recLt2xX9qiAAYXlI",
        "id": "4C",
        "name": "Address  and Remove Impediments, Obstacles, and Blockers"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recjFOJvVNccFs7bk",
      "id": "4.4",
      "name": "Investigate and  interpret the source and stage of a conflict and recommend an appropriate  conflict resolution solution.",
      "eco": [
        "1.1",
        "1.10"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "rectzR8Y3pFeoQoN2",
        "id": "4D",
        "name": "Manage  Conflict"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recsyo2emoU1YyLMw",
      "id": "4.5",
      "name": "Evaluate  stakeholder engagement needs and influence stakeholders to accomplish project  objectives",
      "eco": [
        "1",
        "9"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recjOaRqHC7mxJIQd",
        "id": "4E",
        "name": "Collaborate  with Stakeholders"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec53HqrKe9ddd38V",
      "id": "4.6",
      "name": "Recognize mentoring  opportunities and mentor relevant stakeholders.",
      "eco": [
        "1.3",
        " 1.13"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recfD3BAOYjUfLv1V",
        "id": "4F",
        "name": "Mentor  Relevant Stakeholders"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recQ5ZVP6vp0pomif",
      "id": "4.7",
      "name": "Promote team  performance through the application of emotional intelligence.",
      "eco": [
        "1.3",
        " 1.14"
      ],
      "lesson": "4",
      "topic": {
        "airtableId": "recISuunavocfqFfj",
        "id": "4G",
        "name": "Apply  Emotional Intelligence to Promote Team Performance"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recX6H5lXirDgEd0c",
      "id": "5.1",
      "name": "Determine necessary  approach and action to address compliance needs.",
      "eco": [
        "3",
        "1"
      ],
      "lesson": "5",
      "topic": {
        "airtableId": "recCymabPpVkBZSEC",
        "id": "5A",
        "name": "Manage  Compliance Requirements"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recRaLavAq4VqHZua",
      "id": "5.2",
      "name": "Evaluate delivery  options to achieve benefits and value.",
      "eco": [
        "1.10",
        " 3.2",
        " 3.4"
      ],
      "lesson": "5",
      "topic": {
        "airtableId": "rec5Gvc9fODDOY8fo",
        "id": "5B",
        "name": "Evaluate  and Deliver Project Benefits and Value"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec1RVP58DTj5uXwz",
      "id": "5.3",
      "name": "Continually review  internal and external business environment for impacts on project  scope/backlog.",
      "eco": [
        "3",
        "3"
      ],
      "lesson": "5",
      "topic": {
        "airtableId": "recK6E0tMLut9pNvt",
        "id": "5C",
        "name": "Evaluate  and Address Internal and External Business Environment Changes"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "recsNdhuuUWxaiAB2",
      "id": "5.4",
      "name": "Evaluate he impact  of the project to the organization and determine required actions.",
      "eco": [
        "3",
        "4"
      ],
      "lesson": "5",
      "topic": {
        "airtableId": "recu77Po5pEb94mjU",
        "id": "5D",
        "name": "Support  Organizational Change"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    },
    {
      "airtableId": "rec0n4lCPDbcUFvmE",
      "id": "5.5",
      "name": "Access the existing  continuous improvement framework and execute continuous improvement steps.",
      "lesson": "5",
      "topic": {
        "airtableId": "recfWAcTCdb5M5SqQ",
        "id": "5E",
        "name": "Employ Continuous Process Improvement"
      },
      "enablers": [
        {
          "id": "1",
          "name": "Appraise teams’ skills",
          "eco": "1.6.1"
        },
        {
          "id": "2",
          "name": "Support diversity and inclusion",
          "eco": "1.6.2"
        },
        {
          "id": "3",
          "name": "Determine team member requirements",
          "eco": "1.6.3"
        }
      ],
      "deliverables": [
        {
          "id": "1",
          "name": "Skills list"
        },
        {
          "id": "2",
          "name": "Technology"
        },
        {
          "id": "3",
          "name": "Resource schedule​"
        }
      ],
      "tools": [
        {
          "id": "1",
          "name": "Pre-assignment tools"
        },
        {
          "id": "2",
          "name": "RACI Matrix"
        }
      ],
      "activities": [
        {
          "id": "1.1",
          "name": "Building a Team​",
          "time": "15"
        },
        {
          "id": "1.2",
          "name": "Define Team Ground  Rules",
          "time": "15"
        }
      ]
    }
  ],
  "topics": [
    {
      "airtableId": "recKoSF5Ta1Dfyvwi",
      "id": "1A",
      "name": "Build a Team"
    },
    {
      "airtableId": "rectnuSSemXE8iyvC",
      "id": "1B",
      "name": "Define Team Ground Rules"
    },
    {
      "airtableId": "recknHyuDuMR6GE4Z",
      "id": "1C",
      "name": "Negotiate Project Agreements"
    },
    {
      "airtableId": "recyS4rjoTYG8q6lD",
      "id": "1D",
      "name": "Empower  Team Members and Stakeholders"
    },
    {
      "airtableId": "recCWTTBA9i1qIorb",
      "id": "1E",
      "name": "Train  Team Members and Stakeholders"
    },
    {
      "airtableId": "recL0nRZyhG5KhANz",
      "id": "1F",
      "name": "Engage  and Support Virtual Teams"
    },
    {
      "airtableId": "rec3eIosxQieo4PSG",
      "id": "1G",
      "name": "Build a  Shared Understanding about a Project"
    },
    {
      "airtableId": "recOYAPYvkfi0ReTD",
      "id": "2A",
      "name": "Determine  Appropriate Project Methodology/ Methods and Practices"
    },
    {
      "airtableId": "recSclKGfuDCQPvNw",
      "id": "2B",
      "name": "Plan and Manage Scope"
    },
    {
      "airtableId": "reclysy9qeQDTXLQC",
      "id": "2C",
      "name": "Plan and Manage Budget and Resources"
    },
    {
      "airtableId": "rec1OzSiF4KM7LVDg",
      "id": "2D",
      "name": "Plan and Manage Schedule"
    },
    {
      "airtableId": "rec2gYEEBcPLu6Ha4",
      "id": "2E",
      "name": "Plan and Manage Quality of Products/ Deliverables"
    },
    {
      "airtableId": "recWOLHP7Egix03L8",
      "id": "2F",
      "name": "Integrate Project Planning Activities"
    },
    {
      "airtableId": "recstw8oqSiMpAXsr",
      "id": "2G",
      "name": "Plan and Manage Procurement"
    },
    {
      "airtableId": "rec2d6X47ask6qwCv",
      "id": "2H",
      "name": "Establish Project Governance Structure"
    },
    {
      "airtableId": "recC6XS80q9fhrqqv",
      "id": "2I",
      "name": "Plan and Manage Project/Phase Closure"
    },
    {
      "airtableId": "recIKwqCY7jO7x6na",
      "id": "3A",
      "name": "Assess  and Manage Risks"
    },
    {
      "airtableId": "rec8k4TGYscC6syYZ",
      "id": "3B",
      "name": "Execute  Project to Deliver Business Value"
    },
    {
      "airtableId": "recPNWLVCRTOvRqsE",
      "id": "3C",
      "name": "Manage  Communications"
    },
    {
      "airtableId": "recUwp4UiaUTy6Cjc",
      "id": "3D",
      "name": "Engage Stakeholders"
    },
    {
      "airtableId": "rec0GobxAN9DwNyQx",
      "id": "3E",
      "name": "Create  Project Artifacts"
    },
    {
      "airtableId": "rec6gvyxelBCTfQsU",
      "id": "3F",
      "name": "Manage  Project Changes"
    },
    {
      "airtableId": "recNwONSQ4AeYB3KK",
      "id": "3G",
      "name": "Manage  Project Issues"
    },
    {
      "airtableId": "recYeX7U1URC8VAl8",
      "id": "3H",
      "name": "Ensure  Knowledge Transfer to Project Continuity"
    },
    {
      "airtableId": "recFr6tEtEK2dY0IF",
      "id": "4A",
      "name": "Lead a  Team"
    },
    {
      "airtableId": "recMDBFnOlaNmAisQ",
      "id": "4B",
      "name": "Support  Team Performance"
    },
    {
      "airtableId": "recLt2xX9qiAAYXlI",
      "id": "4C",
      "name": "Address  and Remove Impediments, Obstacles, and Blockers"
    },
    {
      "airtableId": "rectzR8Y3pFeoQoN2",
      "id": "4D",
      "name": "Manage  Conflict"
    },
    {
      "airtableId": "recjOaRqHC7mxJIQd",
      "id": "4E",
      "name": "Collaborate  with Stakeholders"
    },
    {
      "airtableId": "recfD3BAOYjUfLv1V",
      "id": "4F",
      "name": "Mentor  Relevant Stakeholders"
    },
    {
      "airtableId": "recISuunavocfqFfj",
      "id": "4G",
      "name": "Apply  Emotional Intelligence to Promote Team Performance"
    },
    {
      "airtableId": "recCymabPpVkBZSEC",
      "id": "5A",
      "name": "Manage  Compliance Requirements"
    },
    {
      "airtableId": "rec5Gvc9fODDOY8fo",
      "id": "5B",
      "name": "Evaluate  and Deliver Project Benefits and Value"
    },
    {
      "airtableId": "recK6E0tMLut9pNvt",
      "id": "5C",
      "name": "Evaluate  and Address Internal and External Business Environment Changes"
    },
    {
      "airtableId": "recu77Po5pEb94mjU",
      "id": "5D",
      "name": "Support  Organizational Change"
    },
    {
      "airtableId": "recfWAcTCdb5M5SqQ",
      "id": "5E",
      "name": "Employ Continuous Process Improvement"
    }
  ],
  "enablers": [
    {
      "airtableId": "recg8Wwaqu1cLkNRb",
      "id": "recg8Wwaqu1cLkNRb",
      "name": "Assess existing  organization continuous improvement framework.​"
    },
    {
      "airtableId": "recHuuRePr13NtIAu",
      "id": "recHuuRePr13NtIAu",
      "name": "Recommend and execute continuous  improvement steps."
    },
    {
      "airtableId": "rec5phmUs5vIUH3IS",
      "id": "rec5phmUs5vIUH3IS",
      "name": "Plan continuous improvement methods,  procedures, tools.​"
    },
    {
      "airtableId": "rectKb3WUSisxSLtL",
      "id": "rectKb3WUSisxSLtL",
      "name": "Appraise team performance against key performance indicators. (ECO 1.3.1)​",
      "eco": "1.3.1"
    },
    {
      "airtableId": "rectobo4L8GiobMGG",
      "id": "rectobo4L8GiobMGG",
      "name": "Support and recognize team growth and development. (ECO 1.3.2)​",
      "eco": "1.3.2"
    },
    {
      "airtableId": "recgGWxHQ7UwoIGSR",
      "id": "recgGWxHQ7UwoIGSR",
      "name": "Determine appropriate feedback approach. (ECO 1.3.3)​",
      "eco": "1.3.3"
    },
    {
      "airtableId": "recMWvAhqSxwPjTJW",
      "id": "recMWvAhqSxwPjTJW",
      "name": "Verify team member performance improvements. (ECO 1.3.4)​",
      "eco": "1.3.4"
    },
    {
      "airtableId": "recVTTbPlc3AjGs8p",
      "id": "recVTTbPlc3AjGs8p",
      "name": "Support team task accountability. (ECO 1.4.2)",
      "eco": "1.4.2"
    },
    {
      "airtableId": "rec1hsPqcmJLA9aCj",
      "id": "rec1hsPqcmJLA9aCj",
      "name": "Determine risk  management approach. (ECO 2.3.1)​",
      "eco": "2.3.1"
    },
    {
      "airtableId": "recIuj5jp2YsmOc3r",
      "id": "recIuj5jp2YsmOc3r",
      "name": "Iteratively identify, assess and  prioritize risks and risk responses. (ECO 2.3.2)​",
      "eco": "2.3.2"
    },
    {
      "airtableId": "recTPqnYKCr7JwglV",
      "id": "recTPqnYKCr7JwglV",
      "name": "Implement risk response."
    },
    {
      "airtableId": "recZTAQw88mymOSSp",
      "id": "recZTAQw88mymOSSp",
      "name": "Determine risk response. ​"
    },
    {
      "airtableId": "reckYo7XqXLbP83HO",
      "id": "reckYo7XqXLbP83HO",
      "name": "Assess project  needs, complexity, and magnitude. (ECO 2.13.1)​",
      "eco": "2.13.1"
    },
    {
      "airtableId": "recoZHlKKzHmcK3JG",
      "id": "recoZHlKKzHmcK3JG",
      "name": "Use iterative, incremental practices  throughout the project life cycle. (ECO 2.13.4)",
      "eco": "2.13.4"
    },
    {
      "airtableId": "recxklnLAXU7CFHTz",
      "id": "recxklnLAXU7CFHTz",
      "name": "Recommend project execution strategy  (e.g., contracting, finance). (ECO 2.13.2)​",
      "eco": "2.13.2"
    },
    {
      "airtableId": "rec6Zfnyi6w6iPfuz",
      "id": "rec6Zfnyi6w6iPfuz",
      "name": "Recommend a project methodology/approach  (i.e., predictive, agile, hybrid). ​(ECO 2.13.3)​",
      "eco": "2.13.3"
    },
    {
      "airtableId": "recqPmJCrR3bFvYm0",
      "id": "recqPmJCrR3bFvYm0",
      "name": "Recognize when a risk becomes an issue. (ECO 2.15.1)​",
      "eco": "2.15.1"
    },
    {
      "airtableId": "rec0ZwgwU2PzUPXWG",
      "id": "rec0ZwgwU2PzUPXWG",
      "name": "Attack the issue with the optimal action to achieve project success. (ECO 2.15.2)​",
      "eco": "2.15.2"
    },
    {
      "airtableId": "receeJCYKVz7UbQPM",
      "id": "receeJCYKVz7UbQPM",
      "name": "Collaborate with relevant stakeholders on the approach to resolve the issues. ​(ECO 2.15.3)",
      "eco": "2.15.3"
    },
    {
      "airtableId": "recNG1MvldpNOnsbq",
      "id": "recNG1MvldpNOnsbq",
      "name": "Collectively define  ground rules.​"
    },
    {
      "airtableId": "recdaXFJQiePEKdbZ",
      "id": "recdaXFJQiePEKdbZ",
      "name": "Establish an environment that fosters  adherence to ground rules. (ECO 1.12.2)​",
      "eco": "1.12.2"
    },
    {
      "airtableId": "rect8J86yDkNEfD5J",
      "id": "rect8J86yDkNEfD5J",
      "name": "Communicate ground rules with team  members. (ECO 1.12.1)​",
      "eco": "1.12.1"
    },
    {
      "airtableId": "recX17sESqIVzpHsw",
      "id": "recX17sESqIVzpHsw",
      "name": "Manage and rectify ground rule  violations. (ECO 1.12.3)",
      "eco": "1.12.3"
    },
    {
      "airtableId": "reccE9Wuu1dKjpUBH",
      "id": "reccE9Wuu1dKjpUBH",
      "name": "Maintain  team and knowledge transfer. (ECO 1.6.4)​",
      "eco": "1.6.4"
    },
    {
      "airtableId": "recLe4lCPch4qBDoo",
      "id": "recLe4lCPch4qBDoo",
      "name": "Confirm approach for knowledge  transfers. (ECO 2.16.3)",
      "eco": "2.16.3"
    },
    {
      "airtableId": "recRB0fhpBJZEsATS",
      "id": "recRB0fhpBJZEsATS",
      "name": "Discuss project responsibilities within  team. (ECO 2.16.1)​",
      "eco": "2.16.1"
    },
    {
      "airtableId": "rec5xEvsnsbYmkJgW",
      "id": "rec5xEvsnsbYmkJgW",
      "name": "Outline expectations for working  environment. (ECO 2.16.2)​",
      "eco": "2.16.2"
    },
    {
      "airtableId": "receWC0e4rB9Vykm3",
      "id": "receWC0e4rB9Vykm3",
      "name": "Examine virtual team member needs. (ECO 1.11.1)​",
      "eco": "1.11.1"
    },
    {
      "airtableId": "recNG8xWzTbt17KKI",
      "id": "recNG8xWzTbt17KKI",
      "name": "Investigate alternatives for virtual team member engagement. (ECO 1.11.2)​",
      "eco": "1.11.2"
    },
    {
      "airtableId": "recmiFTF8ZJyMYWVk",
      "id": "recmiFTF8ZJyMYWVk",
      "name": "Implement options for virtual team member engagement. (ECO 1.11.3)​",
      "eco": "1.11.3"
    },
    {
      "airtableId": "recQkpf9u1pDd0ju8",
      "id": "recQkpf9u1pDd0ju8",
      "name": "Continually evaluate effectiveness of virtual team member engagement. (ECO 1.11.4)​",
      "eco": "1.11.4"
    },
    {
      "airtableId": "recnmuSS4glx4px7u",
      "id": "recnmuSS4glx4px7u",
      "name": "Assess impact on scope/backlog based on changes in business environment. ​(ECO 3.3.2)​",
      "eco": "3.3.2"
    },
    {
      "airtableId": "rec7mDSxhkvlF6VPR",
      "id": "rec7mDSxhkvlF6VPR",
      "name": "Survey changes to internal and external business environment. (ECO 3.3.1)​",
      "eco": "3.3.1"
    },
    {
      "airtableId": "recFy6KWbSi4YVpFc",
      "id": "recFy6KWbSi4YVpFc",
      "name": "Reprioritize work/actions. ​"
    },
    {
      "airtableId": "rechlSyJAbvz7zOdk",
      "id": "rechlSyJAbvz7zOdk",
      "name": "Review business environment for impacts on scope/backlog. (ECO 3.3.4)",
      "eco": "3.3.4"
    },
    {
      "airtableId": "rec5dvz2OGw4sKqHV",
      "id": "rec5dvz2OGw4sKqHV",
      "name": "Recommend options for changes (e.g., schedule, cost changes). (ECO 3.3.3)​",
      "eco": "3.3.3"
    },
    {
      "airtableId": "recZZG7BrKM2JZ6mZ",
      "id": "recZZG7BrKM2JZ6mZ",
      "name": "Determine the requirements for managing project artifacts. (ECO 2.12.1)​",
      "eco": "2.12.1"
    },
    {
      "airtableId": "recLaOZwKbBnvgY9T",
      "id": "recLaOZwKbBnvgY9T",
      "name": "Validate that the project information is kept up to date and accessible. (ECO 2.12.2)​",
      "eco": "2.12.2"
    },
    {
      "airtableId": "recgg7UrUWNQp6HPO",
      "id": "recgg7UrUWNQp6HPO",
      "name": "Continually assess effectiveness of the management of the project artifacts. ​(ECO 2.12.3)",
      "eco": "2.12.3"
    },
    {
      "airtableId": "recJivL0iyT16Gkp3",
      "id": "recJivL0iyT16Gkp3",
      "name": "Analyze the bounds of the negotiations for agreement. (ECO 1.8.1)​",
      "eco": "1.8.1"
    },
    {
      "airtableId": "recbE3qesTVo0ins1",
      "id": "recbE3qesTVo0ins1",
      "name": "Assess priorities and determine ultimate objective(s). (ECO 1.8.2)​",
      "eco": "1.8.2"
    },
    {
      "airtableId": "recbkHCTos7GXdlgH",
      "id": "recbkHCTos7GXdlgH",
      "name": "Verify objective(s) of the agreement is met. (ECO 1.8.3)​",
      "eco": "1.8.3"
    },
    {
      "airtableId": "recNdtSkkpUWZhIcw",
      "id": "recNdtSkkpUWZhIcw",
      "name": "Participate in agreement negotiations. (ECO 1.8.4)",
      "eco": "1.8.4"
    },
    {
      "airtableId": "rec25bbhbpB53HX6H",
      "id": "rec25bbhbpB53HX6H",
      "name": "Determine a negotiation strategy. (ECO 1.8.5)​",
      "eco": "1.8.5"
    },
    {
      "airtableId": "recaM651EkrBZjp2q",
      "id": "recaM651EkrBZjp2q",
      "name": "Confirm project  compliance requirements. (ECO 3.1.1)​",
      "eco": "3.1.1"
    },
    {
      "airtableId": "recImN1XdhKn4mLUD",
      "id": "recImN1XdhKn4mLUD",
      "name": "Determine potential threats to  compliance. (ECO 3.1.3)​",
      "eco": "3.1.3"
    },
    {
      "airtableId": "recaRBUpJPzsWOQda",
      "id": "recaRBUpJPzsWOQda",
      "name": "Determine necessary actions to address  compliance needs. (ECO 3.1.6) ​",
      "eco": "3.1.6"
    },
    {
      "airtableId": "receqxcNbyxJL1LRi",
      "id": "receqxcNbyxJL1LRi",
      "name": "Use methods to support  compliance. (ECO 3.1.4)​",
      "eco": "3.1.4"
    },
    {
      "airtableId": "recloR7OvStkYQeEk",
      "id": "recloR7OvStkYQeEk",
      "name": "Analyze the consequences of  noncompliance. (ECO 3.1.5)​",
      "eco": "3.1.5"
    },
    {
      "airtableId": "recSMReqeZyQDHQIO",
      "id": "recSMReqeZyQDHQIO",
      "name": "Measure the extent to which the  project is in compliance. (ECO 3.1.7) ",
      "eco": "3.1.7"
    },
    {
      "airtableId": "recV7eQ8OtLQWcrl0",
      "id": "recV7eQ8OtLQWcrl0",
      "name": "Classify compliance categories. (ECO  3.1.2)​",
      "eco": "3.1.2"
    },
    {
      "airtableId": "recDcNZ0lfOa5hWF1",
      "id": "recDcNZ0lfOa5hWF1",
      "name": "Appraise teams’ skills. (ECO 1.6.1)",
      "eco": "1.6.1"
    },
    {
      "airtableId": "rec4whIw9EyQGIZwe",
      "id": "rec4whIw9EyQGIZwe",
      "name": "Support diversity and inclusion. (ECO 1.2.2)",
      "eco": "1.2.2"
    },
    {
      "airtableId": "rec5lOjkm8fhkpWGH",
      "id": "rec5lOjkm8fhkpWGH",
      "name": "Determine team member requirements. (ECO 1.6.2)",
      "eco": "1.6.2"
    },
    {
      "airtableId": "recBR8anKXjrcBShf",
      "id": "recBR8anKXjrcBShf",
      "name": "Continuously assess and refresh team skills. (ECO 1.6.3)",
      "eco": "1.6.3"
    },
    {
      "airtableId": "recDwWLaEoqBiVF7Z",
      "id": "recDwWLaEoqBiVF7Z",
      "name": "Discuss responsibilities within teams. (ECO 2.16.1)",
      "eco": "2.16.1"
    },
    {
      "airtableId": "rec2SDKCbG6ZZVUiU",
      "id": "rec2SDKCbG6ZZVUiU",
      "name": "Maintain team knowledge and transfer. (ECO 1.6.4)",
      "eco": "1.6.4"
    },
    {
      "airtableId": "recd3NFXveEVFVKoP",
      "id": "recd3NFXveEVFVKoP",
      "name": "Determine critical  impediments, obstacles, and blockers for the team. (ECO 1.7.1)​",
      "eco": "1.7.1"
    },
    {
      "airtableId": "recGP8cLV0s1IeYlf",
      "id": "recGP8cLV0s1IeYlf",
      "name": "Implement solutions to remove  impediments, obstacles, and blockers for the team. (ECO 1.7.3)​",
      "eco": "1.7.3"
    },
    {
      "airtableId": "recdiYZlwl9WheyNw",
      "id": "recdiYZlwl9WheyNw",
      "name": "Prioritize critical impediments,  obstacles, and blockers for the team. (ECO 1.7.2)​",
      "eco": "1.7.2"
    },
    {
      "airtableId": "recSMlKqkh5XvwRLa",
      "id": "recSMlKqkh5XvwRLa",
      "name": "Re-assess continually to ensure  impediments, obstacles, and blockers for the team are being addressed.  (ECO 1.7.4)",
      "eco": "1.7.4"
    },
    {
      "airtableId": "recExG00AQvE8hYVh",
      "id": "recExG00AQvE8hYVh",
      "name": "Analyze stakeholders. (ECO 2.4.1)​",
      "eco": "2.4.1"
    },
    {
      "airtableId": "recevKcjwg5tKoXQQ",
      "id": "recevKcjwg5tKoXQQ",
      "name": "Categorize stakeholders. (ECO 2.4.2)​",
      "eco": "2.4.2"
    },
    {
      "airtableId": "recIu6mwgkCycW9rR",
      "id": "recIu6mwgkCycW9rR",
      "name": "Engage stakeholders by category. (ECO 2.4.3)​",
      "eco": "2.4.3"
    },
    {
      "airtableId": "recoJpqc4maSs3i8R",
      "id": "recoJpqc4maSs3i8R",
      "name": "Develop, execute, and validate a strategy for stakeholder engagement. (ECO 2.4.4)​",
      "eco": "2.4.4"
    },
    {
      "airtableId": "recxYBDjqA6KHGM1W",
      "id": "recxYBDjqA6KHGM1W",
      "name": "Determine training options based on training needs. (ECO 1.5.2)​",
      "eco": "1.5.2"
    },
    {
      "airtableId": "recUGYKcf6VPvPXIL",
      "id": "recUGYKcf6VPvPXIL",
      "name": "Determine required competencies and elements of training. (ECO 1.5.1)​",
      "eco": "1.5.1"
    },
    {
      "airtableId": "recDuaqtKwGv12guf",
      "id": "recDuaqtKwGv12guf",
      "name": "Measure training outcomes. (ECO 1.5.4)",
      "eco": "1.5.4"
    },
    {
      "airtableId": "reccd6YG7zteJATer",
      "id": "reccd6YG7zteJATer",
      "name": "Ensure training occurs.​"
    },
    {
      "airtableId": "recffrXhfNWN2sFH7",
      "id": "recffrXhfNWN2sFH7",
      "name": "Allocate resources for training. (ECO 1.5.3)​",
      "eco": "1.5.3"
    },
    {
      "airtableId": "rec17Lwljh9vbgDu8",
      "id": "rec17Lwljh9vbgDu8",
      "name": "Determine  appropriate governance for a project. (ECO 2.14.1)​",
      "eco": "2.14.1"
    },
    {
      "airtableId": "reczIy9X1l0lWtcGx",
      "id": "reczIy9X1l0lWtcGx",
      "name": "Define escalation paths and  thresholds. (ECO 2.14.2)",
      "eco": "2.14.2"
    },
    {
      "airtableId": "recTASo4vDxTzTs02",
      "id": "recTASo4vDxTzTs02",
      "name": "Evaluate engagement needs for stakeholders. (ECO 1.9.1) ​",
      "eco": "1.9.1"
    },
    {
      "airtableId": "reccRprroit4EfScM",
      "id": "reccRprroit4EfScM",
      "name": "Optimize alignment between stakeholder needs, expectations, and project objectives. (ECO 1.9.2)​",
      "eco": "1.9.2"
    },
    {
      "airtableId": "rec0ijskxxJaXBktX",
      "id": "rec0ijskxxJaXBktX",
      "name": "Build trust and influence stakeholders to accomplish project objectives. (ECO 1.9.3)",
      "eco": "1.9.3"
    },
    {
      "airtableId": "recjGIyA7q1rnqaE1",
      "id": "recjGIyA7q1rnqaE1",
      "name": "Build shared  understanding of project and value. ​"
    },
    {
      "airtableId": "recGPFj34x95bnTQd",
      "id": "recGPFj34x95bnTQd",
      "name": "Investigate that benefits are  identified. (ECO 3.2.1)​",
      "eco": "3.2.1"
    },
    {
      "airtableId": "recKdiyNjCLotkciY",
      "id": "recKdiyNjCLotkciY",
      "name": "Evaluate impact to the organization and  determine required actions. (ECO 3.4.3)",
      "eco": "3.4.3"
    },
    {
      "airtableId": "recPfOKXQF74yPLjo",
      "id": "recPfOKXQF74yPLjo",
      "name": "Inform stakeholders of value being  delivered. (ECO 3.2.5)​",
      "eco": "3.2.5"
    },
    {
      "airtableId": "rec0Akj2vIqQcpoyG",
      "id": "rec0Akj2vIqQcpoyG",
      "name": "Document agreement on ownership for  ongoing benefit realization. (ECO 3.2.2)​",
      "eco": "3.2.2"
    },
    {
      "airtableId": "rec5GgoOAAaSTyN20",
      "id": "rec5GgoOAAaSTyN20",
      "name": "Verify measurement system is in place to  track benefits. (ECO 3.2.3)​",
      "eco": "3.2.3"
    },
    {
      "airtableId": "rec6gahIs89nmbO1w",
      "id": "rec6gahIs89nmbO1w",
      "name": "Evaluate delivery options to achieve  benefits. (ECO 3.2.4) ​",
      "eco": "3.2.4"
    },
    {
      "airtableId": "recKbExLva7pZG52S",
      "id": "recKbExLva7pZG52S",
      "name": "Assess  organizational culture. (ECO 3.4.1)​",
      "eco": "3.4.1"
    },
    {
      "airtableId": "recKToQ0JrLl6Dv3C",
      "id": "recKToQ0JrLl6Dv3C",
      "name": "Evaluate impact of organizational change  to determine required actions. (ECO 3.4.2)​",
      "eco": "3.4.2"
    },
    {
      "airtableId": "rec55itushF1XvHAO",
      "id": "rec55itushF1XvHAO",
      "name": "Recommend, plan and facilitate the  changes."
    },
    {
      "airtableId": "rec9QUMaZgfVDrbcd",
      "id": "rec9QUMaZgfVDrbcd",
      "name": "Evaluate impact of the project to the  organization and determine required actions. (ECO 3.4.3)​",
      "eco": "3.4.3"
    },
    {
      "airtableId": "recvFGAZevQftZGn9",
      "id": "recvFGAZevQftZGn9",
      "name": "Examine the business value throughout the project. (ECO 2.1.2)​",
      "eco": "2.1.2"
    },
    {
      "airtableId": "recTlp0kcgyDCWQA8",
      "id": "recTlp0kcgyDCWQA8",
      "name": "Assess opportunities to deliver value incrementally. (ECO 2.1.1)​",
      "eco": "2.1.1"
    },
    {
      "airtableId": "rece9bfuaTULBWWm2",
      "id": "rece9bfuaTULBWWm2",
      "name": "Collect and analyze data to make informed project decisions. (ECO 2.9.4)",
      "eco": "2.9.4"
    },
    {
      "airtableId": "rectmVW2uTLxRLhYl",
      "id": "rectmVW2uTLxRLhYl",
      "name": "Measure ongoing progress based on methodology. (ECO 2.6.4)​",
      "eco": "2.6.4"
    },
    {
      "airtableId": "recxJvPHxfnWRFazF",
      "id": "recxJvPHxfnWRFazF",
      "name": "Support the team to subdivide tasks to find the minimum viable product. (ECO 2.1.3)​",
      "eco": "2.1.3"
    },
    {
      "airtableId": "recSg4YjsxOlhgFk7",
      "id": "recSg4YjsxOlhgFk7",
      "name": "Support diversity and inclusion. (ECO 1.2.2) ​",
      "eco": "1.2.2"
    },
    {
      "airtableId": "rec2cMEA3Vt1L6Ani",
      "id": "rec2cMEA3Vt1L6Ani",
      "name": "Set a clear vision and mission. (ECO 1.2.1) ​",
      "eco": "1.2.1"
    },
    {
      "airtableId": "recEeEbAUDo16dwzU",
      "id": "recEeEbAUDo16dwzU",
      "name": "Analyze team members and stakeholders' influence. (ECO 1.2.6)​",
      "eco": "1.2.6"
    },
    {
      "airtableId": "recehZzoBbD8IpXpw",
      "id": "recehZzoBbD8IpXpw",
      "name": "Value servant leadership. (ECO 1.2.3) ​",
      "eco": "1.2.3"
    },
    {
      "airtableId": "recrG9Dwk0fTTqzj4",
      "id": "recrG9Dwk0fTTqzj4",
      "name": "Inspire, motivate, and influence team member/stakeholders. (ECO 1.2.5)​",
      "eco": "1.2.5"
    },
    {
      "airtableId": "recOFL9jtEAie44DA",
      "id": "recOFL9jtEAie44DA",
      "name": "Distinguish various options to lead various team members and stakeholders. ​(ECO 1.2.7)​",
      "eco": "1.2.7"
    },
    {
      "airtableId": "recQujzcfhETnqrkz",
      "id": "recQujzcfhETnqrkz",
      "name": "Maintain team. (ECO 1.6.4)",
      "eco": "1.6.4"
    },
    {
      "airtableId": "rec09QxZ4LuSUVp0s",
      "id": "rec09QxZ4LuSUVp0s",
      "name": "Determine an appropriate leadership style. (ECO 1.2.4)​",
      "eco": "1.2.4"
    },
    {
      "airtableId": "recb8FAHP4YPaxXWB",
      "id": "recb8FAHP4YPaxXWB",
      "name": "Manage and rectify  ground rule violations. (ECO 1.12.3) ​",
      "eco": "1.12.3"
    },
    {
      "airtableId": "recayBfNvuSOJltsz",
      "id": "recayBfNvuSOJltsz",
      "name": "Collect and analyze data to make informed  project decisions. (ECO 2.9.4)​",
      "eco": "2.9.4"
    },
    {
      "airtableId": "recl4zIipjItmyc4T",
      "id": "recl4zIipjItmyc4T",
      "name": "Assess plans for dependencies, gaps, and  continued business value. (ECO 2.9.2)​",
      "eco": "2.9.2"
    },
    {
      "airtableId": "recnCU82XmGGhFjKW",
      "id": "recnCU82XmGGhFjKW",
      "name": "Plan and manage project compliance to  business factors."
    },
    {
      "airtableId": "recvIEGqZAUnZIqG5",
      "id": "recvIEGqZAUnZIqG5",
      "name": "Analyze the data collected. (ECO  2.9.3)​",
      "eco": "2.9.3"
    },
    {
      "airtableId": "rec1BiAe1dSqyFjSC",
      "id": "rec1BiAe1dSqyFjSC",
      "name": "Determine critical information  requirements. (ECO 2.9.5)​",
      "eco": "2.9.5"
    },
    {
      "airtableId": "rec2XC9sjHyJLQQ0Q",
      "id": "rec2XC9sjHyJLQQ0Q",
      "name": "Consolidate the project/phase  plans. (ECO 2.9.1)​",
      "eco": "2.9.1"
    },
    {
      "airtableId": "rec0RKWohFy7QxeOF",
      "id": "rec0RKWohFy7QxeOF",
      "name": "Interpret the  source and stage of the conflict. (ECO 1.1.1)​",
      "eco": "1.1.1"
    },
    {
      "airtableId": "recFToVHIsjxG6fih",
      "id": "recFToVHIsjxG6fih",
      "name": "Evaluate/recommend/reconcile and track  effectiveness. (ECO 1.1.3)​",
      "eco": "1.1.3"
    },
    {
      "airtableId": "recLWm5171QhWU3RX",
      "id": "recLWm5171QhWU3RX",
      "name": "Analyze the context for the  conflict. (ECO 1.1.2)​",
      "eco": "1.1.2"
    },
    {
      "airtableId": "recSRK7XOuY284oto",
      "id": "recSRK7XOuY284oto",
      "name": "Investigate potential  misunderstandings. (ECO 1.10.4)",
      "eco": "1.10.4"
    },
    {
      "airtableId": "recWptZpa4XlN082e",
      "id": "recWptZpa4XlN082e",
      "name": "Identify the root cause of the  misunderstanding. (ECO 1.10.1)​",
      "eco": "1.10.1"
    },
    {
      "airtableId": "recccl8yD3DNTZxXa",
      "id": "recccl8yD3DNTZxXa",
      "name": "Analyze  communication needs of all stakeholders. (ECO 2.2.1)​",
      "eco": "2.2.1"
    },
    {
      "airtableId": "recmKDprjTS0viTQo",
      "id": "recmKDprjTS0viTQo",
      "name": "Plan communication methods, channels,  frequency, and level of detail. (ECO 2.2.2)​",
      "eco": "2.2.2"
    },
    {
      "airtableId": "recp13rsHBdrSN2jc",
      "id": "recp13rsHBdrSN2jc",
      "name": "Confirm communication is understood and  feedback is received. (ECO 2.2.4)",
      "eco": "2.2.4"
    },
    {
      "airtableId": "rec4SluZj2xIh9u5F",
      "id": "rec4SluZj2xIh9u5F",
      "name": "Communicate project information and  updates effectively. (ECO 2.2.3)​",
      "eco": "2.2.3"
    },
    {
      "airtableId": "recnAvu4Eq6G9IeFt",
      "id": "recnAvu4Eq6G9IeFt",
      "name": "Anticipate and embrace the need for change. (ECO 2.10.1)​",
      "eco": "2.10.1"
    },
    {
      "airtableId": "recsvTQSbom9aVUQI",
      "id": "recsvTQSbom9aVUQI",
      "name": "Determine strategy to handle change. (ECO 2.10.2)​",
      "eco": "2.10.2"
    },
    {
      "airtableId": "recm80SFPUzHGbY6k",
      "id": "recm80SFPUzHGbY6k",
      "name": "Execute change management strategy according to the methodology. (ECO 2.10.3)​",
      "eco": "2.10.3"
    },
    {
      "airtableId": "recUgJaFA5k4NlTrT",
      "id": "recUgJaFA5k4NlTrT",
      "name": "Determine a change response to move the project forward. (ECO 2.10.4)",
      "eco": "2.10.4"
    },
    {
      "airtableId": "recwF4eAcrIkIz5r7",
      "id": "recwF4eAcrIkIz5r7",
      "name": "Organize around  team strengths. (ECO 1.4.1)​",
      "eco": "1.4.1"
    },
    {
      "airtableId": "recgbMMg03pmhBPnY",
      "id": "recgbMMg03pmhBPnY",
      "name": "Support team task  accountability. (ECO 1.4.2)​",
      "eco": "1.4.2"
    },
    {
      "airtableId": "recOV4OVNF9w7n612",
      "id": "recOV4OVNF9w7n612",
      "name": "Evaluate demonstration of task  accountability. (ECO 1.4.3)​",
      "eco": "1.4.3"
    },
    {
      "airtableId": "rec5XtJcFfG001phb",
      "id": "rec5XtJcFfG001phb",
      "name": "Determine and bestow level(s) of  decision-making authority. (ECO 1.4.4)",
      "eco": "1.4.4"
    },
    {
      "airtableId": "recofJc5f3PWAlPBi",
      "id": "recofJc5f3PWAlPBi",
      "name": "Estimate project tasks (milestones, dependencies, story points). (ECO 2.6.1)​",
      "eco": "2.6.1"
    },
    {
      "airtableId": "rectIShzbnxMuEfiP",
      "id": "rectIShzbnxMuEfiP",
      "name": "Utilize benchmarks and historical data. (ECO 2.6.2)​",
      "eco": "2.6.2"
    },
    {
      "airtableId": "recALWdiJlPMWNR3v",
      "id": "recALWdiJlPMWNR3v",
      "name": "Prepare schedule based on methodology. (ECO 2.6.3)​",
      "eco": "2.6.3"
    },
    {
      "airtableId": "recDLi6GVnDq8gz1V",
      "id": "recDLi6GVnDq8gz1V",
      "name": "Measure ongoing progress based on methodology. (ECO 2.6.4)​",
      "eco": "2.6.4"
    },
    {
      "airtableId": "recSkxmyA1N4Rq3EX",
      "id": "recSkxmyA1N4Rq3EX",
      "name": "Coordinate with other projects and other operations. (ECO 2.6.6)",
      "eco": "2.6.6"
    },
    {
      "airtableId": "recXJJGtU5sBQIfGC",
      "id": "recXJJGtU5sBQIfGC",
      "name": "Modify schedule, as needed, based on methodology. (ECO 2.6.5)​",
      "eco": "2.6.5"
    },
    {
      "airtableId": "recUaPhnNy9k5H3iW",
      "id": "recUaPhnNy9k5H3iW",
      "name": "Define external  resource requirements and needs. (ECO 2.11.1)​",
      "eco": "2.11.1"
    },
    {
      "airtableId": "reccZVgal5wlQtkx1",
      "id": "reccZVgal5wlQtkx1",
      "name": "Communicate external resource  requirements. (ECO 2.11.2)​",
      "eco": "2.11.2"
    },
    {
      "airtableId": "receNjyirAxPO6v89",
      "id": "receNjyirAxPO6v89",
      "name": "Develop a delivery solution. (ECO  2.11.5)",
      "eco": "2.11.5"
    },
    {
      "airtableId": "recsu2gSlvGDFbxOR",
      "id": "recsu2gSlvGDFbxOR",
      "name": "Plan and manage procurement  strategy. (ECO 2.11.4)​",
      "eco": "2.11.4"
    },
    {
      "airtableId": "recy2bnd4zYO7KBEG",
      "id": "recy2bnd4zYO7KBEG",
      "name": "Manage suppliers/contracts. (ECO  2.11.3)​",
      "eco": "2.11.3"
    },
    {
      "airtableId": "rec7NjNQRYwZCeKGI",
      "id": "rec7NjNQRYwZCeKGI",
      "name": "Determine criteria to successfully close the project or phase. (ECO 2.17.1)​",
      "eco": "2.17.1"
    },
    {
      "airtableId": "rec5kmRcwqokMgrd0",
      "id": "rec5kmRcwqokMgrd0",
      "name": "Develop transition planning artifacts. ​"
    },
    {
      "airtableId": "recFOsG6yUCOMZ7Xd",
      "id": "recFOsG6yUCOMZ7Xd",
      "name": "Conclude activities to close out project or phase. (ECO 2.17.3)",
      "eco": "2.17.3"
    },
    {
      "airtableId": "recb2Mt63LbQDvxE4",
      "id": "recb2Mt63LbQDvxE4",
      "name": "Validate readiness for transition (e.g., to operations team or next phase). ​(ECO 2.17.2)​",
      "eco": "2.17.2"
    },
    {
      "airtableId": "recH4RHN4JOWGVDk3",
      "id": "recH4RHN4JOWGVDk3",
      "name": "Estimate budgetary  needs. (ECO 2.5.1)​",
      "eco": "2.5.1"
    },
    {
      "airtableId": "recenYtzq3MGx3mDX",
      "id": "recenYtzq3MGx3mDX",
      "name": "Plan and manage resources. (ECO  2.5.4) ",
      "eco": "2.5.4"
    },
    {
      "airtableId": "reclEqTzSfIEoj8HF",
      "id": "reclEqTzSfIEoj8HF",
      "name": "Monitor budget variations and work with  governance process. (ECO 2.5.3)​",
      "eco": "2.5.3"
    },
    {
      "airtableId": "recPqGjFhsqi1cr5L",
      "id": "recPqGjFhsqi1cr5L",
      "name": "Anticipate future budget  challenges. (ECO 2.5.2)​",
      "eco": "2.5.2"
    },
    {
      "airtableId": "recCI1KmeOQmDp8EU",
      "id": "recCI1KmeOQmDp8EU",
      "name": "Plan quality standard required for project deliverables. (ECO 2.7.1 )​",
      "eco": "2.7.1"
    },
    {
      "airtableId": "recy0L3dBFoKe2GB8",
      "id": "recy0L3dBFoKe2GB8",
      "name": "Recommend options for improvement based on quality gaps. (ECO 2.7.2)​",
      "eco": "2.7.2"
    },
    {
      "airtableId": "recwMuHNO2a3uiJKR",
      "id": "recwMuHNO2a3uiJKR",
      "name": "Continually survey project deliverable quality. (ECO 2.7.3)",
      "eco": "2.7.3"
    },
    {
      "airtableId": "recKYdYGrjxh5zo0B",
      "id": "recKYdYGrjxh5zo0B",
      "name": "Determine and  prioritize requirements. (ECO 2.8.1)​",
      "eco": "2.8.1"
    },
    {
      "airtableId": "recPFTmsdRjmScgXO",
      "id": "recPFTmsdRjmScgXO",
      "name": "Monitor, reprioritize and validate  scope. (ECO 2.8.3)",
      "eco": "2.8.3"
    },
    {
      "airtableId": "recQlOrajE3y8fcOX",
      "id": "recQlOrajE3y8fcOX",
      "name": "Build work packages/do some work.​"
    },
    {
      "airtableId": "rec4KCzX6dxCI7ccT",
      "id": "rec4KCzX6dxCI7ccT",
      "name": "Break down scope and define acceptance  criteria (definition of done). (ECO 2.8.2)​",
      "eco": "2.8.2"
    },
    {
      "airtableId": "recJs4WQlqOMJ0EHC",
      "id": "recJs4WQlqOMJ0EHC",
      "name": "Support and  recognize team member growth and development. (ECO 1.3.2)​",
      "eco": "1.3.2"
    },
    {
      "airtableId": "recvyOzYAfaQewfrr",
      "id": "recvyOzYAfaQewfrr",
      "name": "Assess behavior through the use  of personality indicators. (ECO 1.14.1)​",
      "eco": "1.14.1"
    },
    {
      "airtableId": "rec7rBb1sd9bC5MBv",
      "id": "rec7rBb1sd9bC5MBv",
      "name": "Analyze personality indicators and adjust  to needs of key stakeholders. (ECO 1.14.2) ",
      "eco": "1.14.2"
    },
    {
      "airtableId": "rec3oVPaZ2U9D3Wpg",
      "id": "rec3oVPaZ2U9D3Wpg",
      "name": "Survey all parties  to reach consensus. (ECO 1.10.2)​",
      "eco": "1.10.2"
    },
    {
      "airtableId": "recSeevh2bF7oljz9",
      "id": "recSeevh2bF7oljz9",
      "name": "Support outcome of parties'  agreement. (ECO 1.10.3)​",
      "eco": "1.10.3"
    },
    {
      "airtableId": "receh90HpJhObO4Bg",
      "id": "receh90HpJhObO4Bg",
      "name": "Allocate the time for ongoing mentoring. (ECO 1.13.1) ​",
      "eco": "1.13.1"
    },
    {
      "airtableId": "recVKLAiwT8tFQlB8",
      "id": "recVKLAiwT8tFQlB8",
      "name": "Recognize and act on mentoring opportunities. (ECO 1.13.2) ​",
      "eco": "1.13.2"
    },
    {
      "airtableId": "rec6Os8tjXcKRL8eS",
      "id": "rec6Os8tjXcKRL8eS",
      "name": "Determine appropriate feedback for effectiveness. (ECO 1.3.3) ",
      "eco": "1.13.3"
    }
  ],
  "deliverables": [
    {
      "airtableId": "recX6lyMOQbx37w2w",
      "id": "recX6lyMOQbx37w2w",
      "name": "Skills list"
    },
    {
      "airtableId": "recnwLW5ZX2FVp20n",
      "id": "recnwLW5ZX2FVp20n",
      "name": "Technology"
    },
    {
      "airtableId": "reczJlxIfjXwCHRTv",
      "id": "reczJlxIfjXwCHRTv",
      "name": "Resource schedule​"
    },
    {
      "airtableId": "recZkXS3GAoOUIRx4",
      "id": "recZkXS3GAoOUIRx4",
      "name": "Rates​"
    },
    {
      "airtableId": "rec9bIgi6Sx3n1d2b",
      "id": "rec9bIgi6Sx3n1d2b",
      "name": "Resource assignment​"
    },
    {
      "airtableId": "recNgGB46OYJV3Iaf",
      "id": "recNgGB46OYJV3Iaf",
      "name": "Team charter​"
    },
    {
      "airtableId": "recMWwtjgprnMULn0",
      "id": "recMWwtjgprnMULn0",
      "name": "Team norms​"
    },
    {
      "airtableId": "recZpqUuKagT6XLTH",
      "id": "recZpqUuKagT6XLTH",
      "name": "Service Level  Agreement​"
    },
    {
      "airtableId": "recai3lQ23e6rA757",
      "id": "recai3lQ23e6rA757",
      "name": "Performance report​"
    },
    {
      "airtableId": "recZHtcSES3cte0el",
      "id": "recZHtcSES3cte0el",
      "name": "Resource calendars​"
    },
    {
      "airtableId": "recbXclGYBtf5VSqL",
      "id": "recbXclGYBtf5VSqL",
      "name": "Go-Live Blackouts​"
    },
    {
      "airtableId": "recdpM7PGsEw98ZrH",
      "id": "recdpM7PGsEw98ZrH",
      "name": "Decisions​"
    },
    {
      "airtableId": "recU6pfCUt7kAFloI",
      "id": "recU6pfCUt7kAFloI",
      "name": "Estimates​"
    },
    {
      "airtableId": "recn8LHN4tOv698pl",
      "id": "recn8LHN4tOv698pl",
      "name": "Training and  mentoring plan​"
    },
    {
      "airtableId": "recTUIHDuAq9xcWrE",
      "id": "recTUIHDuAq9xcWrE",
      "name": "Training cost estimates​"
    },
    {
      "airtableId": "reckHEJO5qONhzInl",
      "id": "reckHEJO5qONhzInl",
      "name": "Training calendar​"
    },
    {
      "airtableId": "recsN5zRdECH86W6S",
      "id": "recsN5zRdECH86W6S",
      "name": "Training assessment​"
    },
    {
      "airtableId": "recWyOuxupDKnDmdv",
      "id": "recWyOuxupDKnDmdv",
      "name": "Certifications​"
    },
    {
      "airtableId": "reccbCsnGwld5Wghi",
      "id": "reccbCsnGwld5Wghi",
      "name": "Collaboration  technology​"
    },
    {
      "airtableId": "recMPx3yHuy5XP2XG",
      "id": "recMPx3yHuy5XP2XG",
      "name": "Engagement assessments​"
    },
    {
      "airtableId": "recKKzl3SwClPIQZb",
      "id": "recKKzl3SwClPIQZb",
      "name": "Calendar tools​"
    },
    {
      "airtableId": "recX9ao0eIZtClbPo",
      "id": "recX9ao0eIZtClbPo",
      "name": "Vision​"
    },
    {
      "airtableId": "rec5mZYjo5Q57hCZF",
      "id": "rec5mZYjo5Q57hCZF",
      "name": "XP Metaphor​"
    },
    {
      "airtableId": "rec78TdyIwYtX6jgs",
      "id": "rec78TdyIwYtX6jgs",
      "name": "Product box exercise​"
    },
    {
      "airtableId": "recfDWAtEd10UqcyV",
      "id": "recfDWAtEd10UqcyV",
      "name": "Create survey​"
    },
    {
      "airtableId": "recmgn0kz4KEBuCU2",
      "id": "recmgn0kz4KEBuCU2",
      "name": "Project business case / needs document​"
    },
    {
      "airtableId": "rec0aKE4sAFI4B32G",
      "id": "rec0aKE4sAFI4B32G",
      "name": "Project Overview Statement​"
    },
    {
      "airtableId": "reczxTxNxd43UhnIt",
      "id": "reczxTxNxd43UhnIt",
      "name": "Project Implementation Plan​"
    },
    {
      "airtableId": "recNjFQkwcc97Hpm9",
      "id": "recNjFQkwcc97Hpm9",
      "name": "Agile practice guidelines​"
    },
    {
      "airtableId": "recO2k9TuciCwfv1k",
      "id": "recO2k9TuciCwfv1k",
      "name": "Requirements Register​"
    },
    {
      "airtableId": "recpXGLhgieuyDU4I",
      "id": "recpXGLhgieuyDU4I",
      "name": "Work performance reports​"
    },
    {
      "airtableId": "recpsR1Gm7A99r7X5",
      "id": "recpsR1Gm7A99r7X5",
      "name": "Traceability matrix​"
    },
    {
      "airtableId": "recBwcSqKe0KGK1ig",
      "id": "recBwcSqKe0KGK1ig",
      "name": "Cost baseline​"
    },
    {
      "airtableId": "rec73y94z1YbclPr2",
      "id": "rec73y94z1YbclPr2",
      "name": "Management reserve​"
    },
    {
      "airtableId": "recLOAmGfj9BcbTO0",
      "id": "recLOAmGfj9BcbTO0",
      "name": "Resource management plan​"
    },
    {
      "airtableId": "rec7YQTmDMQD2GW8f",
      "id": "rec7YQTmDMQD2GW8f",
      "name": "Change requests​"
    },
    {
      "airtableId": "recXjFWhXMW8RxnIV",
      "id": "recXjFWhXMW8RxnIV",
      "name": "Cost forecasts​"
    },
    {
      "airtableId": "recY1AwnjlbcIDawV",
      "id": "recY1AwnjlbcIDawV",
      "name": "Risk analysis​"
    },
    {
      "airtableId": "recxU6LqBwcxAfYll",
      "id": "recxU6LqBwcxAfYll",
      "name": "Activity cost  estimates​"
    },
    {
      "airtableId": "rec4SxqlhYBgNTElu",
      "id": "rec4SxqlhYBgNTElu",
      "name": "Activity duration estimates​"
    },
    {
      "airtableId": "rec8rjsOq74KKAuT5",
      "id": "rec8rjsOq74KKAuT5",
      "name": "Task estimates​"
    },
    {
      "airtableId": "recfOr3GTS0XNggEe",
      "id": "recfOr3GTS0XNggEe",
      "name": "Story estimates​"
    },
    {
      "airtableId": "recVQt25FSAroHAod",
      "id": "recVQt25FSAroHAod",
      "name": "Feature estimates​"
    },
    {
      "airtableId": "recSiljcvdtW2769H",
      "id": "recSiljcvdtW2769H",
      "name": "Updated documents​"
    },
    {
      "airtableId": "recZ5Geb3RGH9UpwG",
      "id": "recZ5Geb3RGH9UpwG",
      "name": "Backlog​"
    },
    {
      "airtableId": "recVM8ZfmOzsCCcFH",
      "id": "recVM8ZfmOzsCCcFH",
      "name": "Velocity data​"
    },
    {
      "airtableId": "rec3f4i4jiTPs5dQ0",
      "id": "rec3f4i4jiTPs5dQ0",
      "name": "Project schedule​"
    },
    {
      "airtableId": "rece8dXqNxyI5b0pP",
      "id": "rece8dXqNxyI5b0pP",
      "name": "Release plan​"
    },
    {
      "airtableId": "rec8ycqP5TovZH0Em",
      "id": "rec8ycqP5TovZH0Em",
      "name": "Product Roadmaps​"
    },
    {
      "airtableId": "recf0IGmftrVTqbtY",
      "id": "recf0IGmftrVTqbtY",
      "name": "Earned Value​"
    },
    {
      "airtableId": "rec4bgUyzyLCcsRjs",
      "id": "rec4bgUyzyLCcsRjs",
      "name": "Updated schedule​"
    },
    {
      "airtableId": "recLaCa4OYuqety07",
      "id": "recLaCa4OYuqety07",
      "name": "Updated release plan​"
    },
    {
      "airtableId": "recwLR4NFMUtLKQ9q",
      "id": "recwLR4NFMUtLKQ9q",
      "name": "Updated product backlog​"
    },
    {
      "airtableId": "recEQpiPYI9Z8ntZ2",
      "id": "recEQpiPYI9Z8ntZ2",
      "name": "Network diagram​"
    },
    {
      "airtableId": "recj4Jnh8Oe5rJ1RA",
      "id": "recj4Jnh8Oe5rJ1RA",
      "name": "Planning meetings​"
    },
    {
      "airtableId": "recbrdurARSPGShpz",
      "id": "recbrdurARSPGShpz",
      "name": "Negotiations​"
    },
    {
      "airtableId": "recY3dDhh0Xp7aCHZ",
      "id": "recY3dDhh0Xp7aCHZ",
      "name": "Quality Management  Plan​"
    },
    {
      "airtableId": "recfQG62ZExFDghKr",
      "id": "recfQG62ZExFDghKr",
      "name": "Define Quality Metrics​"
    },
    {
      "airtableId": "rec92u9zcEktHwMpd",
      "id": "rec92u9zcEktHwMpd",
      "name": "Quality Assurance​"
    },
    {
      "airtableId": "reci1v41GQWowv8f4",
      "id": "reci1v41GQWowv8f4",
      "name": "Quality Control​"
    },
    {
      "airtableId": "rec17FNINMySLXxoT",
      "id": "rec17FNINMySLXxoT",
      "name": "Statement of Work​"
    },
    {
      "airtableId": "recjXRwzTgbgFiIKL",
      "id": "recjXRwzTgbgFiIKL",
      "name": "Procurement Management Plan​"
    },
    {
      "airtableId": "recblZn5WEpJvYdeP",
      "id": "recblZn5WEpJvYdeP",
      "name": "Source selection criteria​"
    },
    {
      "airtableId": "receCHsXnOsGhbJIs",
      "id": "receCHsXnOsGhbJIs",
      "name": "Select sellers​"
    },
    {
      "airtableId": "recJtYA1c7fDDJghb",
      "id": "recJtYA1c7fDDJghb",
      "name": "Monitor work and changes​"
    },
    {
      "airtableId": "rec0oZG05u7TlI1gi",
      "id": "rec0oZG05u7TlI1gi",
      "name": "Create project team​"
    },
    {
      "airtableId": "rec3rKsCautA4qthn",
      "id": "rec3rKsCautA4qthn",
      "name": "Identify governance structure​"
    },
    {
      "airtableId": "recObnzFVHyOlLLYE",
      "id": "recObnzFVHyOlLLYE",
      "name": "Project change / configuration  control ​"
    },
    {
      "airtableId": "recJqYQTmb6W8cBF0",
      "id": "recJqYQTmb6W8cBF0",
      "name": "Definition of Done​"
    },
    {
      "airtableId": "recuqBsjklM6OHCid",
      "id": "recuqBsjklM6OHCid",
      "name": "Validate work​"
    },
    {
      "airtableId": "recpMeGBFnvAZadjA",
      "id": "recpMeGBFnvAZadjA",
      "name": "Risk Management  Plan​"
    },
    {
      "airtableId": "rec1tzzyP1SBPqBhI",
      "id": "rec1tzzyP1SBPqBhI",
      "name": "Risk Register​"
    },
    {
      "airtableId": "rec5qut4HELwowDjw",
      "id": "rec5qut4HELwowDjw",
      "name": "Create Risk Response Plan​"
    },
    {
      "airtableId": "recyGx8u8KGRgpnzD",
      "id": "recyGx8u8KGRgpnzD",
      "name": "Implement Risk Response Plan​"
    },
    {
      "airtableId": "recPKdCH6fwk3Cd0c",
      "id": "recPKdCH6fwk3Cd0c",
      "name": "Communications  Management Plan​"
    },
    {
      "airtableId": "recvD4bT6b5semkZ0",
      "id": "recvD4bT6b5semkZ0",
      "name": "Project communications​"
    },
    {
      "airtableId": "recMNJbFRmU4JrW8o",
      "id": "recMNJbFRmU4JrW8o",
      "name": "Stakeholder Register​"
    },
    {
      "airtableId": "recYX0NfDsTqpv1tz",
      "id": "recYX0NfDsTqpv1tz",
      "name": "Work performance and change updates​"
    },
    {
      "airtableId": "recbroK65n7WDcGky",
      "id": "recbroK65n7WDcGky",
      "name": "Update project communications​"
    },
    {
      "airtableId": "recLxXpSoPUgPejvy",
      "id": "recLxXpSoPUgPejvy",
      "name": "Create Stakeholder  Register​"
    },
    {
      "airtableId": "reczEq15e1ZtRokZf",
      "id": "reczEq15e1ZtRokZf",
      "name": "Update Stakeholder Register​"
    },
    {
      "airtableId": "reclmQofPyeTUiKlk",
      "id": "reclmQofPyeTUiKlk",
      "name": "Stakeholder Engagement Plan​"
    },
    {
      "airtableId": "rec3nNa2914nuljCG",
      "id": "rec3nNa2914nuljCG",
      "name": "Assess work performance information​"
    },
    {
      "airtableId": "rec5Sm4k0HjKQbTAz",
      "id": "rec5Sm4k0HjKQbTAz",
      "name": "Issues Log​"
    },
    {
      "airtableId": "rec5T6EInJvzllo4O",
      "id": "rec5T6EInJvzllo4O",
      "name": "Risk Register​"
    },
    {
      "airtableId": "recrIlNbGIgieJtEW",
      "id": "recrIlNbGIgieJtEW",
      "name": "Stakeholders Register​"
    },
    {
      "airtableId": "recsC7XJS7RnW5zXd",
      "id": "recsC7XJS7RnW5zXd",
      "name": "Updated Issues Log​"
    },
    {
      "airtableId": "recjU0V4wj6IsNaWl",
      "id": "recjU0V4wj6IsNaWl",
      "name": "Vision / Mission  document​"
    },
    {
      "airtableId": "recEX921eUQzpKW2B",
      "id": "recEX921eUQzpKW2B",
      "name": "Charter​"
    },
    {
      "airtableId": "recVsXQIuvPWCkSlc",
      "id": "recVsXQIuvPWCkSlc",
      "name": "Product box​"
    },
    {
      "airtableId": "recVF5CFOmQ96KfWF",
      "id": "recVF5CFOmQ96KfWF",
      "name": "Reward and Recognition Plan​"
    },
    {
      "airtableId": "recQuqw9o7IyqbdVo",
      "id": "recQuqw9o7IyqbdVo",
      "name": "RACI matrix​"
    },
    {
      "airtableId": "recmXY5CHLSwXBDfL",
      "id": "recmXY5CHLSwXBDfL",
      "name": "Management by Objectives​"
    },
    {
      "airtableId": "recJlerToXH67yqZ8",
      "id": "recJlerToXH67yqZ8",
      "name": "Benchmarking​"
    },
    {
      "airtableId": "recnyCmYGvr7m2qVS",
      "id": "recnyCmYGvr7m2qVS",
      "name": "Performance reports​"
    },
    {
      "airtableId": "recWG8dMpiymyyVBl",
      "id": "recWG8dMpiymyyVBl",
      "name": "Reprioritize  backlog​"
    },
    {
      "airtableId": "rec0bv3MkI9cWT2wR",
      "id": "rec0bv3MkI9cWT2wR",
      "name": "Updated risk lists​"
    },
    {
      "airtableId": "recMaQwsJzTchZXJx",
      "id": "recMaQwsJzTchZXJx",
      "name": "Action plans​"
    },
    {
      "airtableId": "rec1QyhWpg8t9WJPd",
      "id": "rec1QyhWpg8t9WJPd",
      "name": "Updated impediment task board​"
    },
    {
      "airtableId": "recCIWyi3WBDhFWzV",
      "id": "recCIWyi3WBDhFWzV",
      "name": "Updated working  agreement​"
    },
    {
      "airtableId": "recp5oQJGQZsZPK5J",
      "id": "recp5oQJGQZsZPK5J",
      "name": "Updated RACI matrix​"
    },
    {
      "airtableId": "reclM9jJIb1U5Ttke",
      "id": "reclM9jJIb1U5Ttke",
      "name": "Stakeholder  Registers​"
    },
    {
      "airtableId": "recOQDqkuFgMLKMEC",
      "id": "recOQDqkuFgMLKMEC",
      "name": "Stakeholder Engagement Plan​"
    },
    {
      "airtableId": "recuKwBBfh4KA2E6G",
      "id": "recuKwBBfh4KA2E6G",
      "name": "Training and  mentoring plan​"
    },
    {
      "airtableId": "recj0bl3mRmJ8AvhL",
      "id": "recj0bl3mRmJ8AvhL",
      "name": "Effective assessment​"
    },
    {
      "airtableId": "recFNYz382FiWXJzN",
      "id": "recFNYz382FiWXJzN",
      "name": "Training schedule​"
    },
    {
      "airtableId": "recsRPMOhjzsrBgbK",
      "id": "recsRPMOhjzsrBgbK",
      "name": "Personality Profile  Assessments​"
    },
    {
      "airtableId": "rec4DfDjgvJOJHDRg",
      "id": "rec4DfDjgvJOJHDRg",
      "name": "Communications plans​"
    },
    {
      "airtableId": "recMAXGbmvlbYqVwK",
      "id": "recMAXGbmvlbYqVwK",
      "name": "Motivation triggers​"
    },
    {
      "airtableId": "recH3H26bdCDDZtxQ",
      "id": "recH3H26bdCDDZtxQ",
      "name": "Performance reports​"
    },
    {
      "airtableId": "rec2gdvMhjPMyQMkI",
      "id": "rec2gdvMhjPMyQMkI",
      "name": "Risk lists​"
    },
    {
      "airtableId": "recGLGHX6MoH6M3NV",
      "id": "recGLGHX6MoH6M3NV",
      "name": "Risk Register​"
    },
    {
      "airtableId": "recmG7y7G56Xn7d1x",
      "id": "recmG7y7G56Xn7d1x",
      "name": "Configuration Management System​"
    },
    {
      "airtableId": "recjVB3fggAdgdZBK",
      "id": "recjVB3fggAdgdZBK",
      "name": "Execution reports​"
    },
    {
      "airtableId": "rec1mazPDpphiudL3",
      "id": "rec1mazPDpphiudL3",
      "name": "Nonfunctional requirements​"
    },
    {
      "airtableId": "recQhuBCpmE0tIa6w",
      "id": "recQhuBCpmE0tIa6w",
      "name": "Signoffs / Approvals​"
    },
    {
      "airtableId": "recRYx1h4X9kUWy1Q",
      "id": "recRYx1h4X9kUWy1Q",
      "name": "QA Outputs​"
    },
    {
      "airtableId": "rectkOfT84Yq9Yw35",
      "id": "rectkOfT84Yq9Yw35",
      "name": "Quality Management Plan ​"
    },
    {
      "airtableId": "recuWBX1BDWsKv6aP",
      "id": "recuWBX1BDWsKv6aP",
      "name": "Benefits Management  Plan​"
    },
    {
      "airtableId": "recgCUJxYkWg0f30D",
      "id": "recgCUJxYkWg0f30D",
      "name": "EVM, ETC analysis​"
    },
    {
      "airtableId": "recjvpqKOMQhcGejO",
      "id": "recjvpqKOMQhcGejO",
      "name": "ROI, NPV, IRR​"
    },
    {
      "airtableId": "recoetsZZQgi4G5Dj",
      "id": "recoetsZZQgi4G5Dj",
      "name": "Benefit Cost Analysis​"
    },
    {
      "airtableId": "recVZdgpvus7hVPAa",
      "id": "recVZdgpvus7hVPAa",
      "name": "Decision Trees, EMV​"
    },
    {
      "airtableId": "recxrTtS0qRt0bHBH",
      "id": "recxrTtS0qRt0bHBH",
      "name": "Monte Carlo​"
    },
    {
      "airtableId": "recpw5R1YBMttygWq",
      "id": "recpw5R1YBMttygWq",
      "name": "Net Promoter Score​"
    },
    {
      "airtableId": "recnxxMU6crO0kw7X",
      "id": "recnxxMU6crO0kw7X",
      "name": "A/B Testing​"
    },
    {
      "airtableId": "recE4sEPr1eaTrI6T",
      "id": "recE4sEPr1eaTrI6T",
      "name": "Update Baselines​"
    },
    {
      "airtableId": "reciicmVwSMNSFkM2",
      "id": "reciicmVwSMNSFkM2",
      "name": "Configuration Management System​"
    },
    {
      "airtableId": "recdlouIleByYJnxT",
      "id": "recdlouIleByYJnxT",
      "name": "Reprioritize Backlog​"
    },
    {
      "airtableId": "recj5Vv1OKn2ayXDJ",
      "id": "recj5Vv1OKn2ayXDJ",
      "name": "Updated Roadmaps ​"
    },
    {
      "airtableId": "recD4jaCm039blTct",
      "id": "recD4jaCm039blTct",
      "name": "Change Management Plan​"
    },
    {
      "airtableId": "rec9ms7i9rKpF1qsS",
      "id": "rec9ms7i9rKpF1qsS",
      "name": "Roll Out Plan​"
    },
    {
      "airtableId": "recuuRoh3ZX42H0AU",
      "id": "recuuRoh3ZX42H0AU",
      "name": "Training Plan​"
    },
    {
      "airtableId": "recdJoPAhEyI0VpeX",
      "id": "recdJoPAhEyI0VpeX",
      "name": "Training Artifacts​"
    },
    {
      "airtableId": "recRaZ6OxocNATDH6",
      "id": "recRaZ6OxocNATDH6",
      "name": "Update processes and  standards"
    }
  ],
  "tools": [],
  "activities": []
}`)

export default testData

<template>
  <div class="page-container">
    <h1 class="page-title">Create Account</h1>

    <div v-if="error" class="alert alert-error">
      {{error.message}}

      <router-link
        to="/reset-password"
        v-if="error.code === 'auth/email-already-in-use'"
        class="link-error"
      >Click here if you forgot your password?
      </router-link>
    </div>

    <form action='#' @submit.prevent='submit'>
      <label for="first-name">First Name</label>
      <input
        type="name"
        id="first-name"
        name="first-name"
        value
        required
        autofocus
        v-model='form.firstName'
      >

      <label for="last-name">Last Name</label>
      <input
        type="name"
        id="last-name"
        name="last-name"
        value
        required
        v-model='form.lastName'
      >

      <label for="last-name">Company</label>
      <input
        type="text"
        id="company"
        name="company"
        value
        v-model='form.company'
      >

      <label for="email">Email</label>
      <input
        id='email'
        type='email'
        name='email'
        value
        required
        autofocus
        v-model='form.email'
      />

      <label for="password">Password</label>
      <input
        id='password'
        type='password'
        name='password'
        required
        v-model='form.password'
      />

      <button type="submit" class="button-primary button-create-account">Create Account</button>
    </form>

    <p class="account-footer-box">
      Already have an account? <router-link to="/login">Sign In</router-link>
    </p>
  </div>

</template>

<script>
import store from './../store'
import { db, auth } from './../firebase'

export default {
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: '',
        homepage: 'ccmp'
      },
      error: null
    }
  },

  methods: {

    submit () {
      auth
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          // Update profile
          data.user.updateProfile({
            displayName: `${this.form.firstName} ${this.form.lastName}`
          })

          // Send email verification
          data.user.sendEmailVerification({
            url: process.env.VUE_APP_URL + '/',
            handleCodeInApp: false
          }).then(() => {
            console.log('Email verification sucessfully sent')
          }).catch(error => {
            console.error('Could not send email verification: ', error)
          })

          // Save user data data to Firestore
          db.collection('users').doc(data.user.uid).set({
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            company: this.form.company,
            homepage: this.form.homepage
          }).then(() => {
            store.dispatch('setCurrentUser', auth.currentUser)
            this.$router.push('/email-verification')
          }).catch((error) => {
            console.error('Error adding user data: ', error)
          })
        }).catch(error => {
          this.error = error
        })
    }
  }
}
</script>

<style lang="scss">

  .button-create-account {
    margin-top: 30px;
  }

  .account-footer-box {
    text-align: center;
  }

  .link-error {
    color: #fff;
  }
</style>

<template>
  <div class="page-container">
    <h1 class="page-title">Payment Declined</h1>
    <span class="spacer-20"></span>
    <p>We are so sorry your payment didn’t go through. Please, can you try again? If you believe you did everything correctly and are still having problems, please contact <a href="mailto:support@jtask.com">support@jtask.com</a>.</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

<template>
  <div class="page-container">
    <h1 class="page-title">Forgot your Password?</h1>
    <p class="page-description">No problem! Just enter your email below and we will send a link to reset your password.</p>
    <span class="spacer-50"></span>

    <div v-if="errorMessage" class="alert alert-error"> {{errorMessage}} </div>
    <div v-if="successMessage" class="alert alert-success"> {{successMessage}} </div>

    <form action='#' @submit.prevent='submit'>
      <label for="email">Email</label>
      <input
        id='email'
        type='email'
        name='email'
        value
        required
        autofocus
        v-model='form.email'
      />

      <button type="submit" class="button-primary button-send">Send</button>
    </form>
  </div>

</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      form: {
        email: ''
      },
      errorMessage: null,
      successMessage: null
    }
  },

  methods: {

    submit () {
      this.errorMessage = null
      this.successMessage = null

      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email, {
          url: process.env.VUE_APP_URL + '/#/login',
          handleCodeInApp: false
        })
        .then(data => {
          this.successMessage = 'Reset email sent. Please check your inbox.'
        })
        .catch(err => {
          this.errorMessage = err.message
        })
    }
  }
}

</script>

<style lang="scss">

  .button-send {
    margin-top: 30px;
  }

  .account-footer-box {
    text-align: center;
  }
</style>

<template>
  <div class="page-container">
    <h1 class="page-title">Privacy Policy</h1>
    <span class="spacer-20"></span>
    <p>We promise to always treat your information with respect.</p>
    <p>You have the right to be informed, access, rectify, erase, restrict, request, and get data you provided, object to us processing your data. You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</p>
    <p>We use HubSpot as our marketing automation platform. By registering for jTask CCMP Webapp you acknowledge that the information you provide will be transferred to our CRM, HubSpot.</p>
    <p>You can change your mind at any time about receiving emails by clicking the unsubscribe link in the footer of any email you receive from us.</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

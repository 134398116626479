<template>
  <div class="page-container-alt">
    <h1 class="page-title">Get CCMP PRO Content</h1>

    <span v-if="isCcmpPro" class="button-secondary">CCMP PRO is activated</span>
    <router-link v-if="!isCcmpPro" to="/unlock-pro" class="button-secondary">
      <i class="material-icons">shopping_cart</i> Unlock CCMP PRO USD $69.00
    </router-link>

    <div>
      <p>If you sign up for <a href="https://jtask.com/ccmp-training/" title="jTask CCMP Training" target="_blank">jTask CCMP
      Training</a>, the PRO version of the webapp is included free of charge.</p>
      <p>PRO content is derived from the ACMP®️ Standard for Change Management©️ and includes:</p>
      <ul class="features-list">
        <li>ACMP Progress Group, Steps, Purpose Statements, Input, and Output content.</li>
        <li>jTask content to provide deeper insight into how to use the Standard.</li>
        <li>jTask notes and templates to support important change management process steps.</li>
        <li>jTask top knowledge base questions such as “How you can build your own change management methodology or playbook” and “How to build a change agent network and identify change agents?”.</li>
        <li>jTask CCMP Training courses quizzes (110 questions).</li>
        <li>ACMP Code of Ethics check.</li>
      </ul>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  computed: {
    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

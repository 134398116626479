<template>
  <div class="page-container">
    <h1 class="page-title">Payment Successful</h1>
    <span class="spacer-20"></span>
    <p>Congratulations! You’ve signed up for jTask CCMP WebApp Pro. Now you can access all the jTask CCMP Training content, based on the ACMP Standard, to help you do change management work. </p>
    <p>Also, as part of Pro content you get access to our quizzes. Look for the link at the bottom of each lesson page. </p>
    <p>Thank you for your business.</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import firebase from 'firebase'
import store from '../store/index'

import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import EmailVerification from '../views/EmailVerification.vue'
import Welcome from '../views/Welcome.vue'
import Account from '../views/Account.vue'
import Privacy from '../views/Privacy.vue'
import Legal from '../views/Legal.vue'
import About from '../views/About.vue'

import CcmpLessons from '../views/CcmpLessons.vue'
import CcmpProTasks from '../views/CcmpProTasks.vue'

import CcmpProOverview from '../views/CcmpProOverview.vue'
import UnlockPro from '../views/UnlockPro.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentDeclined from '../views/PaymentDeclined.vue'

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: { hideForAuth: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { hideForAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { hideForAuth: true }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: { hideForAuth: true }
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: EmailVerification,
    meta: { requiresAuth: true }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: { requiresAuth: true }
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true }
  },

  // CCMP Routes
  {
    path: '/ccmp/lessons',
    name: 'ccmp-lessons',
    component: CcmpLessons,
    meta: { requiresAuth: true }
  },
  {
    path: '/ccmp/lesson/:id',
    name: 'ccmp-lesson',
    props: true,
    component: () => import('../views/CcmpLesson.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ccmp/lesson/:id/pro',
    name: 'ccmp-pro',
    props: true,
    component: () => import('../views/CcmpPro.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ccmp/objective/:id',
    name: 'ccmp-objective',
    props: true,
    component: () => import('../views/CcmpObjective.vue'),
    meta: { requiresAuth: true }
  },

  // CCMP Pro Routes
  {
    path: '/ccmp-pro/tasks',
    name: 'ccmp-pro-tasks',
    component: CcmpProTasks,
    meta: {
      requiresAuth: true,
      isProPage: true
    }
  },
  {
    path: '/ccmp-pro/task/:id',
    name: 'ccmp-pro-task',
    props: true,
    component: () => import('../views/CcmpProTask.vue'),
    meta: {
      requiresAuth: true,
      isProPage: true
    }
  },
  {
    path: '/ccmp-pro/task/:id/pro-content',
    name: 'ccmp-pro-content',
    props: true,
    component: () => import('../views/CcmpProContent.vue'),
    meta: {
      requiresAuth: true,
      isProPage: true
    }
  },
  {
    path: '/ccmp-pro/objective/:id',
    name: 'ccmp-pro-objective',
    props: true,
    component: () => import('../views/CcmpProObjective.vue'),
    meta: {
      requiresAuth: true,
      isProPage: true
    }
  },
  {
    path: '/ccmp-pro/templates/',
    name: 'ccmp-pro-templates',
    props: true,
    component: () => import('../views/CcmpProTemplates.vue'),
    meta: {
      requiresAuth: true,
      isProPage: true
    }
  },

  // Payment Routes
  {
    path: '/ccmp-pro-overview',
    name: 'ccmp-pro-overview',
    component: CcmpProOverview,
    meta: { requiresAuth: true }
  },
  {
    path: '/unlock-pro',
    name: 'unlock-pro',
    component: UnlockPro,
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-success',
    name: 'payment-success',
    component: PaymentSuccess,
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-declined',
    name: 'payment-declined',
    component: PaymentDeclined,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const sleep = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Handle authentications
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const hideForAuth = to.matched.some(record => record.meta.hideForAuth)
  const isProPage = to.matched.some(record => record.meta.isProPage)

  if (hideForAuth) {
    if (hideForAuth && await firebase.getCurrentUser()) {
      next('home')
    } else {
      next()
    }
  }

  if (requiresAuth) {
    const currentUser = await firebase.getCurrentUser()

    if (requiresAuth && !currentUser) {
      next('/')
    } else {
      // Check if email is verified
      if (currentUser.emailVerified) {
        // Check if this is a pro page
        while (!store.state.user) {
          await sleep(300)
        }
        const userHasProAccess = store.state.account.ccmpPro
        console.log('userHasProAccess: ', userHasProAccess)
        if (isProPage && !userHasProAccess) {
          next('ccmp-pro-overview')
        }

        next()
      } else {
        if (to.name !== 'email-verification') {
          next('email-verification')
        } else {
          next()
        }
      }
    }
  }
})

export default router

<template>
  <div class="page-container">
    <h1 class="page-title">Legal</h1>
    <span class="spacer-20"></span>
    <p>jTask is a QEP®️ (Qualified Education Provider) and uses the ACMP Standard for Change Management to develop educational materials with permission from the ACMP, since 2015. </p>
    <p>jTask™️, the jTask logo, jTask Consulting™️, jTask CCMP Training™️, jTask CCMP WebApp™️ and jTask Pulse™️ are trademarks of jTask, Inc. ​</p>
    <p>The ACMP logo, ACMP®️, QEP®️, Qualified Education Professional®️ and the Association of Change Management Professionals®️ are registered trademarks. </p>
    <p>Certified Change Management Professional™️ (CCMP™️) is a trademark of ACMP. The Standard for Change Management© is copyrighted by the ACMP.</p>
    <p>PMI®️, PMP®️ and PMBOK®️ Guide are registered trademarks of the Project Management Institute®️.</p>
    <p>All other trademarks, registered trademarks, service marks, and related branding marks (possibly unmarked) used in this app are the legal property of their respective owner.</p>
    <span class="spacer-50"></span>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },

  methods: {

  }
}

</script>

<style lang="scss">

</style>
